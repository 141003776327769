import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { Header } from '../components/Header';
import useTitle from '../hooks/useTitle';
import useDebounce from "../hooks/useDebounce";
import { Accordion, Stack } from "react-bootstrap";
import { getBarsIcon, getMoonIcon } from "../services/icons";
import RequestForm from "../components/RequestForm/RequestForm";


const bgElement106 = new URL("../assets/images/106.png", import.meta.url);
const bgElement107 = new URL("../assets/images/107.png", import.meta.url);

export const FaqPage = () => {
  const [focused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const accordionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useTitle('CopyMaster – your assistant in crypto-trading');

  const faqBuyContent = [
    {
      id: 1,
      title: 'Why aren\'t coins showing up after they\'ve been purchased?',
      content: 'Coins are displayed only after the BUY/SELL cycle is completed'
    },
    {
      id: 2,
      title: 'What is a BUY cycle?',
      content: 'A BUY cycle means that the coin is first bought and then sold.\n\nExample:\n\nYou bought 200 coins for $100 at a price of 0.5, then you sold 200 coins at a price of $1. Thus, the BUY cycle is completed.'
    },
    {
      id: 3,
      title: 'What is the SELL cycle?',
      content: 'A SELL cycle means that the coin is first sold and then bought.\n\nExample:\n\nYou sold 200 coins at $1 and got $200, then you bought $200 at 0.5 400 coins. This completes the SELL cycle.'
    },
  ];

  const faqStartContent = [
    {
      id: 1,
      title: 'How to add API key ?',
      content: 'You can familiarize yourself with the video instruction [Link]'
    }
  ]

  useEffect(() => {
    if (debouncedSearchQuery) {
      // Find the index of the first matching FAQ item
      const index = [...faqBuyContent, ...faqStartContent].findIndex(item =>
        item.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
        item.content.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
      );

      if (index !== -1) {
        const faqSection = document.getElementById('faqContent');
        if (faqSection) {
          faqSection.scrollIntoView({behavior: 'smooth'});
        }
      }
    }
  }, [debouncedSearchQuery]);

  const filterContent = (content: { title: string, content: string }[], refs: (HTMLDivElement | null)[]) => {
    return content.map((item, index) => {
      const highlightClass = highlightContent(item.title, item.content);
      return {...item, highlightClass, ref: refs[index]};
    });
  };

  const highlightContent = (title: string, content: string) => {
    if (!searchQuery) return '';
    const queryInTitle = title.toLowerCase().includes(searchQuery.toLowerCase());
    const queryInContent = content.toLowerCase().includes(searchQuery.toLowerCase());
    return queryInTitle || queryInContent ? 'highlighted-title' : '';
  };

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Header/>

      <main className='position-relative'
            style={{maxWidth: '78rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <img src={bgElement106.href} alt=""
             style={{top: '-25px', width: 'auto', height: 'auto', left: '-55px', zIndex: '-1'}}
             className='position-absolute'/>
        <img src={bgElement107.href} alt=""
             style={{bottom: '-25px', width: 'auto', height: 'auto', right: '-50px', zIndex: '-1'}}
             className='position-absolute'/>
        <section>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3} className='mt-5 pt-3'>
              <h1 style={{fontSize: '40px'}} className='text-center unbounded-bold'>FAQ</h1>
            </Stack>
          </Stack>
          <Stack direction='horizontal' gap={3} className='account__bgfield flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3}>
              <h2 className='text-center roboto-bold mb-2' style={{fontSize: '32px'}}>How we can help you?</h2>
              <div
                className='text-center position-relative'
                style={{
                  backgroundColor: '#13141D',
                  maxWidth: '560px',
                  width: '100%',
                  margin: '0 auto',
                  borderRadius: '1.45rem',
                  overflow: 'hidden'
                }}>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  type='text'
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder='Type something'
                  pattern='[0-9]*\.{0,1}[0-9]{0,8}'
                  className='text__input position-relative'
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: '48px',
                    textIndent: '60px',
                    outline: 'none',
                    border: '0',
                    borderRadius: '1.45rem',
                    color: '#fff',
                    zIndex: '2'
                  }}
                />
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  className='position-absolute'
                  style={{top: '40%', left: "25px", zIndex: '1'}}
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="6" cy="6" r="5.5" stroke='#ffffff'/>
                  <path d="M9 10L13 14" stroke='#ffffff'/>
                </svg>
              </div>
            </Stack>
          </Stack>
        </section>
        <section>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3} className='mt-5 pt-3'>
              <h2 style={{fontSize: '32px'}} className='text-center roboto-bold'>Self Service</h2>
            </Stack>
          </Stack>
          <div id="faqContent">
            <Stack direction='horizontal' gap={3} className='account__bgfield flex-wrap flex-lg-nowrap mt-5'>
              <Stack direction='vertical' gap={3}>
                <h3 className='roboto-bold mb-2 text-left d-flex align-items-center gap-4'
                    style={{marginLeft: '-30px', fontSize: '24px', color: '#377BFF'}}>{getBarsIcon()} How to
                  start</h3>
                <Accordion className='accordion-faq'>
                  {filterContent(faqStartContent, accordionRefs.current).map((item, index) => {
                    return (
                      <Accordion.Item eventKey={String(index)} key={index}>
                        <Accordion.Header ref={(el: any) => accordionRefs.current[index] = el as HTMLDivElement}
                                          className={item.highlightClass}>
                          {item.title}
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.content}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Stack>
            </Stack>
            <Stack direction='horizontal' gap={3} className='account__bgfield flex-wrap flex-lg-nowrap mt-5'>
              <Stack direction='vertical' gap={3}>
                <h3 className='roboto-bold mb-2 text-left d-flex align-items-center gap-4'
                    style={{marginLeft: '-30px'}}>{getMoonIcon()} Buy coins
                </h3>
                <Accordion className='accordion-faq'>
                  {filterContent(faqBuyContent, accordionRefs.current).map((item, index) => {
                    return (
                      <Accordion.Item eventKey={String(index)} key={index}>
                        <Accordion.Header ref={(el: any) => accordionRefs.current[index] = el as HTMLDivElement}
                                          className={item.highlightClass}>
                          {item.title}
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.content}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </Stack>
            </Stack>
          </div>
        </section>
        <section>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3} className='mt-5 pt-3'>
              <h2 style={{fontSize: '32px'}} className='text-center roboto-bold'>Don’t find the answer?
                Describe your problem and we will help you</h2>
            </Stack>
          </Stack>
          <Stack direction='horizontal' gap={3} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3}>
              <RequestForm placeholder="Describe your problem" image={true}/>
            </Stack>
          </Stack>
        </section>
      </main>
    </>
  );
};

export default FaqPage;