import React, { ChangeEvent, ClipboardEvent, FC, useEffect, useId, useState } from 'react';

interface Props {
  inputValue?: number | string,
  placeHolder?: string
  classname?: string
  counterRound?: number
  entryPrice?: string | undefined
  sendData?: (data: { newValue: string | number, focused: boolean, type?: string }) => void,
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  name?: string | undefined
  focus?: boolean
}

export const MarketsTableInput: FC<Props> = ({
                                               inputValue = '',
                                               placeHolder = '',
                                               classname = '',
                                               entryPrice,
                                               counterRound = '',
                                               sendData,
                                               onKeyDown,
                                               name,
                                               focus = false,
                                             }) => {
  const [value, setValue] = useState(inputValue);
  const [focused, setFocused] = useState(false)

  const id = useId();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // Заміна коми на крапку
    newValue = newValue.replace(/,/g, '.');

    const scale = counterRound ? Number(counterRound) : 2;

    // Обмеження 2 цифр після крапки
    const [integerPart, decimalPart] = newValue.split('.');
    if (decimalPart && decimalPart.length > scale) {
      newValue = `${integerPart}.${decimalPart.slice(0, scale)}`;
    }

    // Дозволяємо вводити тільки цифри та один раз крапку
    newValue = newValue.replace(/[^\d.]/g, '');

    // Додаємо додаткову перевірку для запобігання більше ніж одній крапці
    const dotCount = newValue.split('.').length - 1;
    if (dotCount > 1) {
      const lastDotIndex = newValue.lastIndexOf('.');
      newValue = newValue.slice(0, lastDotIndex) + newValue.slice(lastDotIndex + 1);
    }

    //setValue(!isTpSl ? newValue : isTpSl === "stopLoss" && entryPrice && newValue < entryPrice ? newValue : isTpSl === "takeProfit" && entryPrice ? newValue : '' );
    setValue(newValue);
    if (sendData) {
      sendData({newValue, focused, type: name})
    }
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData('text/plain');
    // Виконуємо ті ж перевірки для вставленого значення
    let newValue = pastedValue.replace(/,/g, '.');
    const [integerPart, decimalPart] = newValue.split('.');
    if (decimalPart && decimalPart.length > 2) {
      newValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
    newValue = newValue.replace(/[^\d.]/g, '');
    const dotCount = newValue.split('.').length - 1;
    if (dotCount > 1) {
      const lastDotIndex = newValue.lastIndexOf('.');
      newValue = newValue.slice(0, lastDotIndex) + newValue.slice(lastDotIndex + 1);
    }

    setValue(newValue);
  };

  const handleFocus = () => {
    setFocused(true);
    if (sendData) {
      sendData({newValue: value, focused: true, type: name});
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (sendData) {
      sendData({newValue: value, focused: false, type: name});
    }
  };

  useEffect(() => {
    setValue(inputValue); // Update state when inputValue prop changes
  }, [inputValue]);

  return (
    <input
      id={id}
      name={name}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type='text'
      value={value}
      onKeyDown={onKeyDown}
      onChange={handleInputChange}
      onPaste={handlePaste}
      placeholder={placeHolder}
      pattern='[0-9]*\.{0,1}[0-9]{0,8}'
      className={`markets-table__input ${classname}`}
    />
  );
};