import React, { FC, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { ModalDeleteApi } from '../../Modals/ModalDeleteApi/ModalDeleteApi';
import { getAddictionIcon, getPencilIcon, getTrashIcon } from "../../../services/icons";
import useAPIStore from "../../../services/hooks/useFetchedDataStore";
import Switch from "../../Switch/Switch";
import useFocus from "../../../hooks/useFocus";

interface Props {
  data?: any,
  groupName?: string,
  mainApi?: any,
}

const defaultLogo = new URL("../../../assets/images/avatars/binance.png", import.meta.url);

export const ControlApiSubCard: FC<Props> = ({data, groupName, mainApi}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(data.inProcess);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAPI, setDeleteAPI] = useState({
    apiName: '',
    apiKey: 0,
    mainUid: 0,

  });
  const [apiName, setApiName] = useState<string>(data?.name);
  const [readOnlyName, setReadOnlyName] = useState<boolean>(true);
  const [nameRef, setNameFocus] = useFocus<HTMLInputElement>();
  const {
    fetchData
  } = useAPIStore();

  async function handleRemoveFromGroup() {

    const removeData = {
      "market": "BINANCE",
      "mainUid": mainApi,
      "groupName": groupName,
      "removeUids": [data?.uid]
    };

    try {
      const response = await fetch('/api/key/group/remove', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removeData)
      });


      if (response.ok) {
        await fetchData();
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  function handleDeleteApi() {
    setDeleteAPI({
      apiName: data?.name,
      apiKey: data?.uid,
      mainUid: mainApi,
    });
    setShowDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  async function handleSwitchChange(isChecked: boolean) {
    setIsSwitchOn(isChecked);
    const switchData = {
      "market": "BINANCE",
      "mainUid": mainApi,
      "updateUid": data?.uid,
      "inProcess": !isSwitchOn
    };

    try {
      const response = await fetch('/api/key/group/updateKey', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(switchData)
      });


      if (response.ok) {
        await fetchData();
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  //Edit API name
  function handleChangeName(event: any) {
    setApiName(event.target.value);
  }

  function handleEditName(event: any) {
    setReadOnlyName(false);
    setNameFocus();
  }

  function handleNameKeyDown(event: any) {

    if (event.keyCode === 13 && apiName) {
      setReadOnlyName(true);

      const dataApi = {
        mainUid: mainApi,
        market: "binance",
        name: apiName,
        updateUid: data.uid,
      };

      fetch('/api/key', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataApi),
      })
        .then((response) => {
          if (response.ok) {
            return fetchData();
          } else {
            return response.json().then((errorData) => {
              console.error('Server error:', errorData);
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  return (
    <>
      <Stack direction="horizontal" gap={5} className='justify-content-between ms-md-5 pe-4 api-subrow'>
        <div>
          {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={120} width={120}/>}
        </div>
        <Stack direction="vertical" gap={2}>
          <Row className='align-items-end'>
            <Col className='text-secondary p-0' xs={1}>API name</Col>
            <Col className='account__grey'>
              <input
                type="text"
                value={apiName}
                onChange={handleChangeName}
                onKeyDown={handleNameKeyDown}
                style={readOnlyName ? {
                  borderRadius: '30px',
                  textIndent: '15px',
                  padding: '5px 0',
                } : {borderRadius: '30px', textIndent: '15px', outline: '1px solid grey', padding: '5px 0'}}
                className={`bg-transparent account__input text-white border-0`}
                ref={nameRef}
                readOnly={readOnlyName}
                name='apiName'
              />
              <button
                type='button'
                className='bg-transparent'
                onClick={handleEditName}
              >{getPencilIcon('0.8rem', '0.8rem')}</button>
            </Col>
            <Col xs={1}>
              {<Switch
                id="unique-switch-id"
                label=""
                isChecked={isSwitchOn}
                onChange={handleSwitchChange}
              />}
            </Col>
          </Row>
          <Row>
            <Col className='text-secondary p-0' xs={1}>API key</Col>
            <Col className='account__grey' style={{textIndent: '15px'}}>
              {data.key}
            </Col>
            <Col xs={1}>
              <button
                className='alerts-table__icon'
                style={{marginRight: 0, marginLeft: 'auto'}}
                onClick={handleDeleteApi}
              >
                {getTrashIcon("#8997DC")}
              </button>
            </Col>
          </Row>
          <Row>
            <Col className='text-secondary p-0' xs={1}>Market</Col>
            <Col className='account__grey' style={{textIndent: '15px'}}>{data.market}</Col>
            <Col xs={1}>
              <button
                className='alerts-table__icon'
                disabled
                style={{marginRight: 0, marginLeft: 'auto'}}
              >
                {getAddictionIcon("#7B7C85")}
              </button>
            </Col>
          </Row>
          <Row>
            <Col className='text-secondary p-0' xs={1}>Balance</Col>
            <Col
              style={{
                fontSize: '0.8rem',
                fontWeight: '700',
                color: '#36FF6C',
                textIndent: '15px'
              }}>{data.usdtBalance} USDT</Col>
            <Col xs={1}>
              <button
                className='alerts-table__icon'
                style={{marginRight: 0, marginLeft: 'auto'}}
                onClick={handleRemoveFromGroup}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                  <path d="M14.0869 2.08594L1.91401 2.08629" stroke="#8997DC" strokeWidth="2.5" strokeLinecap="round"/>
                </svg>
              </button>
            </Col>
          </Row>
        </Stack>
      </Stack>

      {showConfirmModal &&
          <ModalDeleteApi show={showConfirmModal} onClose={() => setShowConfirmModal(false)}/>
      }
      {showDeleteModal &&
          <ModalDeleteApi
              del={deleteAPI.apiKey}
              mainUid={deleteAPI.mainUid}
              name={deleteAPI.apiName}
              show={showDeleteModal}
              onClose={handleDeleteClose}
          />
      }

    </>
  );
};