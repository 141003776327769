import React, { FC, useContext, useState } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import bigDecimal from 'js-big-decimal';
import classNames from 'classnames';

import './Calculator.scss';
import { CalculatorButtonType } from '../../types/enums';
import { CalculatorInput } from './CalculatorInput/CalculatorInput';
import MarketPriceProvider from '../../context/MarketPriceProvider';
import useNewOrderStore from "../../services/hooks/useNewOrderStore";

interface Props {
  currency: string[],
  type: CalculatorButtonType,
  balance?: number,
  balanceCurrency?: string,
  currencyPairData?: { name: string; market: string; baseRound: number; counterRound: number; },
  counterEarning?: boolean,
  onSubmit: () => void
}

export const Calculator: FC<Props> = ({
                                        currency,
                                        type,
                                        balance,
                                        balanceCurrency,
                                        currencyPairData,
                                        counterEarning,
                                        onSubmit
                                      }) => {
  const [inputPrice, setInputPrice] = useState<string | number>('');
  const [inputQuantity, setInputQuantity] = useState<string | number>('');
  const [inputAll, setInputAll] = useState<string | number>('');
  const navigate = useNavigate();

  const {marketPriceContext: marketPrice} = useContext(MarketPriceProvider);

  const handleSetInputQuantity = (value: number | string) => {
    setInputQuantity(() => Number(new bigDecimal(value).round(currencyPairData?.baseRound, bigDecimal.RoundingModes.DOWN).getValue()));

    if (+inputPrice > 0 && +value > 0) {
      setInputAll(() => (Math.floor((+inputPrice * +value) * 10000) / 10000));
      return;
    }

    setInputAll('');
  };

  const handleSetInputAll = (value: number | string) => {
    setInputAll(() => value);

    if (+value <= 0) {
      setInputQuantity('');
      return;
    }

    const price = inputPrice ? inputPrice : marketPrice;

    if (!inputPrice) {
      setInputPrice(() => marketPrice);
    }

    setInputQuantity(() => Math.floor(+value / +price));
  };

  const handleSetInputPrice = (value: number | string) => {

    let newValue = value.toString();

    newValue = newValue.replace(/,/g, '.');

    const scale = currencyPairData?.counterRound ? Number(currencyPairData?.counterRound) : 2;

    const [integerPart, decimalPart] = newValue.split('.');
    if (decimalPart && decimalPart.length > scale) {
      newValue = `${integerPart}.${decimalPart.slice(0, scale)}`;
    }

    newValue = newValue.replace(/[^\d.]/g, '');

    const dotCount = newValue.split('.').length - 1;
    if (dotCount > 1) {
      const lastDotIndex = newValue.lastIndexOf('.');
      newValue = newValue.slice(0, lastDotIndex) + newValue.slice(lastDotIndex + 1);
    }

    setInputPrice(() => newValue);

    if (+newValue > 0 && +inputQuantity > 0) {
      setInputAll(() => Math.floor((+newValue * +inputQuantity) * 10000) / 10000);
      return;
    }

    setInputAll('');
  };

  const makeOrder = () => {
    const data = {
      "market": currencyPairData?.market?.toLowerCase(),
      "symbolName": currencyPairData?.name?.toLowerCase(),
      "price": Number(inputPrice),
      "counterQuantity": Number(inputAll),
      "counterEarning": counterEarning
    };

    const id = toast.loading("Please wait...", {closeButton: true});

    fetch(`/api/markets/spot/first/${type.toLowerCase()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {

          return response.json();
        } else {
          return response.json().then(errorData => {
            throw new Error(errorData.error);
          });
        }
      })
      .then(data => {
        if (data.error === "Filter failure: PERCENT_PRICE_BY_SIDE") {
          toast.update(id, {
            render: 'Request falls outside the acceptable price range based on the average of previous trades',
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        } else if (data.error === "Filter failure: NOTIONAL") {
          toast.update(id, {
            render: 'Total value of the order should be more than 5USDT',
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        } else if (data.error === "Not enough quantity") {
          toast.update(id, {
            render: 'Not enough quantity',
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        } else {
          toast.update(id, {
            render: 'Order successfully placed',
            type: 'success',
            autoClose: 5000,
            isLoading: false
          });

          const newOrderData = {
            baseQuantityStep: data.orderBlock.symbol.baseQuantityStep,
            baseRound: 0,
            blockId: data.orderBlock.blockId,
            counterEarning: data.orderBlock.counterEarning,
            counterRound: data.orderBlock.symbol.counterRound,
            firstFilledCounterQuantity: 0,
            firstFilledPrice: 0,
            firstFilledQuantity: 0,
            firstFilledTime: 0,
            firstFilledUnrealizedQuantity: 0,
            firstOrderId: data.orderBlock.firstOrderId,
            firstPlacedPrice: data.orderBlock.firstPlacedPrice,
            firstPlacedQuantity: data.orderBlock.firstPlacedQuantity,
            firstPlacedTime: data.orderBlock.firstPlacedTime,
            open: data.orderBlock.open,
            secondFirstStopLossFilledCounterQuantity: 0,
            secondFirstStopLossPlacedCounterQuantity: 0,
            secondFirstStopLossPlacedQuantity: 0,
            secondFirstTakeProfitFilledCounterQuantity: 0,
            secondFirstTakeProfitPlacedCounterQuantity: 0,
            secondFirstTakeProfitPlacedQuantity: 0,
            secondStopLossFilledCounterQuantity: 0,
            secondStopLossFilledPrice: 0,
            secondStopLossFilledQuantity: 0,
            secondStopLossFilledTime: 0,
            secondStopLossOrderId: 0,
            secondStopLossPlacedCounterQuantity: 0,
            secondStopLossPlacedPrice: 0,
            secondStopLossPlacedQuantity: 0,
            secondStopLossPlacedTime: 0,
            secondTakeProfitFilledCounterQuantity: 0,
            secondTakeProfitFilledPrice: 0,
            secondTakeProfitFilledQuantity: 0,
            secondTakeProfitFilledTime: 0,
            secondTakeProfitOrderId: 0,
            secondTakeProfitPlacedCounterQuantity: 0,
            secondTakeProfitPlacedPrice: 0,
            secondTakeProfitPlacedQuantity: 0,
            secondTakeProfitPlacedTime: 0,
            section: data.orderBlock.section,
            userId: data.userId,
            userName: data.orderBlock.userName,
            symbol: {
              name: data.orderBlock.symbol.name,
              market: data.orderBlock.symbol.market,
              baseStepSize: data.orderBlock.symbol.baseStepSize,
              counterStepSize: data.orderBlock.symbol.counterStepSize,
              minCounterQuantity: data.orderBlock.symbol.minCounterQuantity,
              baseCurrency: data.orderBlock.symbol.baseCurrency,
              counterCurrency: data.orderBlock.symbol.counterCurrency,
              baseRound: data.orderBlock.symbol.baseRound,
              counterRound: data.orderBlock.symbol.counterRound,
              baseQuantityStep: data.orderBlock.symbol.baseQuantityStep,
            }
          };

          const {setGroupInfo, addNewOrder} = useNewOrderStore.getState();

          addNewOrder(data.orderBlock.blockId, newOrderData);
          setGroupInfo(data.inGroup);
          onSubmit();

          if (type === "Buy") {
            navigate(`/markets/spot/list?market=${currencyPairData?.market.toLocaleLowerCase()}&symbolName=${currencyPairData?.name.toLocaleLowerCase()}&counterEarning=${counterEarning}&section=buy`);
          } else {
            navigate(`/markets/spot/list?market=${currencyPairData?.market.toLocaleLowerCase()}&symbolName=${currencyPairData?.name.toLocaleLowerCase()}&counterEarning=${counterEarning}&section=sell`);
          }
          setInputPrice('');
          setInputQuantity('');
          setInputAll('');
        }

      })
      .catch(error => {
        toast.update(id, {
          render: error.message,
          type: 'error',
          autoClose: 5000,
          isLoading: false
        });
        console.error('Network error:', error);
      });
  };


  return (
    <>
      <Stack gap={2} className='calculator'>
        <Row>
          <Col className='mb-2' style={{fontSize: '20px'}}>
            Balance
            <span className='calculator__balance'>
              {balance && `${balance} `} {balanceCurrency}
            </span>
          </Col>
        </Row>

        <Row className='align-items-center'>
          <Col xs={3}>
            Price
          </Col>
          <Col>
            <CalculatorInput inputValue={inputPrice} setInputValue={handleSetInputPrice}/>
          </Col>
          <Col xs={2} className='text-uppercase'>
            {currency[1]}
          </Col>
        </Row>

        <Row className='align-items-center'>
          <Col xs={3}>
            Quantity
          </Col>
          <Col>
            <CalculatorInput inputValue={inputQuantity} setInputValue={handleSetInputQuantity}/>
          </Col>
          <Col xs={2} className='text-uppercase'>
            {currency[0]}
          </Col>
        </Row>

        <Row className='align-items-center'>
          <Col xs={3}>
            All
          </Col>
          <Col>
            <CalculatorInput inputValue={inputAll} setInputValue={handleSetInputAll}/>
          </Col>
          <Col xs={2} className='text-uppercase'>
            {currency[1]}
          </Col>
        </Row>

        <Row>
          <button
            className={classNames('calculator__button', {
              buy: type === CalculatorButtonType.buy,
              sell: type === CalculatorButtonType.sell,
            })}
            onClick={makeOrder}
            disabled={inputPrice && inputQuantity ? false : true}
          >
            {type}
          </button>
        </Row>
      </Stack>
    </>
  );
};