import React, { FC } from 'react';
import { Modal, Stack } from 'react-bootstrap';

interface Props {
  show: boolean,
  onClose: () => void,
}

export const ModalVideo: FC<Props> = ({show, onClose}) => {

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' size='xl' fullscreen='sm-down' centered
           className=''>
      <Modal.Header className='d-flex justify-content-center p-4' closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='mx-auto w-100 p-0'>

        <Stack direction='vertical'>
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content">

                <div className="modal__video-align">
                  <iframe
                    className="modal__video-style w-100"
                    loading="lazy"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/wosg6RL-Ytc"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </Stack>
      </Modal.Body>
      <Modal.Footer className='justify-content-center p-0'>
      </Modal.Footer>
    </Modal>
  )
}