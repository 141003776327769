import { create } from 'zustand'

interface FetchedDataState {
  fetchedData: any;
  setFetchedData: (data: any) => void;
  fetchData: () => Promise<void>;
}

const useAPIStore = create<FetchedDataState>((set) => ({
  fetchedData: null,
  fetchData: async () => {
    try {
      const response = await fetch('/api/key/all', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const data = await response.json();
        
        set({fetchedData: data});
      } else {
        const errorData = await response.json();
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  },
  setFetchedData: (data) => set({fetchedData: data}),
}));

export default useAPIStore;
