import React, { FC, useState } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { getBotIcon } from "../../../../services/icons";
import classNames from "classnames";


interface Props {
  show: boolean,
  botTitle: string,
  onClose: () => void,
  handler: () => void
  activeTab: any
}


export const ModalBotTemplateSellBuyLevels: FC<Props> = ({show, onClose, handler, botTitle, activeTab}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [botName, setBotName] = useState<string>('Bot Name');
  const [currentTab, setCurrentTab] = useState(activeTab)
  const [currentAction, setCurrentAction] = useState('USDT');

  function handleChange(event: any) {
    setBotName(event.target.value)
  }

  function handleKeyDown(event: any) {
    if (event.keyCode === 13) {
      setReadOnly(true)
    }
  }

  function handleBlur() {
    if (setBotName.length > 1) {
      setReadOnly(true)
    }
  }

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' fullscreen='sm-down' centered size='xl'
           className="modal--bot">
      <div className='tabs__switcher position-absolute d-flex'>
        {['Buy/Sell', 'Sell/Buy'].map((item, index) =>
          <p
            key={index}
            className={classNames(`tab__switcher w-50 py-2 h-100 d-flex align-items-center justify-content-center position-relative ${index === 0 ? 'px-4' : 'px-3'} `, {active: item === currentTab})}
            onClick={() => setCurrentTab(item)}
          >
            {item}
          </p>
        )}
      </div>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className='px-5 py-3'>
        <Stack direction='horizontal' gap={3} className='bot__header justify-content-center w-100'>
          <div className="d-fllex flex-col align-items-center justify-content-center text-center">
            <h2>Sell/Buy</h2>
          </div>
        </Stack>

        <Stack direction='horizontal' gap={3} className='bot__header w-100 mt-3'>
          <div className="d-fllex flex-col w-100">
            <p className='pb-4'>Choose your earn :</p>
            <div className='modal__switcher'>
              {['USDT', 'XRP'].map((item, index) =>
                <p
                  key={index}
                  className={classNames('markets__switcher w-50 px-5 py-0', {active: item === currentAction})}
                  onClick={() => setCurrentAction(item)}
                >
                  {item}
                </p>
              )}
            </div>
          </div>
        </Stack>
        <Stack direction='vertical' gap={2} className='w-100'>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-4'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>Bot type</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#377BFF'}}>{botTitle}</div>
                {getBotIcon()}
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Market price</div>
                <div className='d-flex pe-4'>
                  <div className='roboto-bold bot__text pe-2' style={{color: '#377BFF'}}>0.5085475</div>
                  <div className='roboto-regular bot__text'>USDT</div>
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between gap-3 mt-4'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>Coin pair</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    style={{borderRadius: '12px', maxWidth: '205px'}}
                    className='p-0 text-start px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.55 USDT'
                  />
                </div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Buy</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.55 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-1'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>PNL</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#36FF6C'}}>+0.67 USDT</div>
                <div className='roboto-regular bot__text pe-2' style={{color: '#36FF6C'}}>+0.01%</div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Sell</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.55 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-1'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>You have</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#36FF6C'}}>0.00004568</div>
                <div className='roboto-regular bot__text pe-2'>USDT</div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Invest</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.00004568 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-center w-100 gap-3 mt-3'>
            <div className='d-flex gap-2 justify-content-center text-center mt-2'>
              <input
                type="checkbox"
                name='repeatCycle'
                className='p-0 text-end px-3'
              />
              Repeat the circle after completion
            </div>
          </div>
        </Stack>
        <Stack direction='horizontal' gap={3} className='w-100 justify-content-evenly mt-4'>
          <Button variant='secondary' className='px-5 py-2 roboto-bold' style={{borderRadius: '35px'}}
                  onClick={() => onClose()}>Reset changes</Button>
          <Button variant='primary' className='px-5 py-2 roboto-bold' style={{borderRadius: '35px'}}
          > Save</Button>
        </Stack>
      </Modal.Body>
      <Modal.Footer className='py-3'>
      </Modal.Footer>
    </Modal>
  )
}