import React, { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { avatars } from "../../../assets/data/datalist";
import useUserStore from "../../../services/hooks/useUserStore";

interface Props {
  show: boolean,
  onClose: () => void
}

export const ModalChangeProfilePhoto: FC<Props> = ({show, onClose}) => {
  const {fetchUserData, updateUserData} = useUserStore();
  const [chosenAvatar, setChosenAvatar] = useState(1);

  const handleImageChange = () => {
    onClose()
    updateUserData("iconIdx", chosenAvatar)
    fetchUserData();
  }

  return (
    <Modal show={show} size='lg' onHide={onClose} data-bs-theme='dark' fullscreen='sm-down' centered>
      <Modal.Header closeButton>
        <Modal.Title>Change profile photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex gap-4 justify-content-center flex-wrap'>
          {avatars.slice(1).map(avatar =>
            <img
              src={avatar.logo.href}
              alt='Avatar'
              style={{
                width: '7rem',
                height: '7rem',
                cursor: 'pointer',
                border: chosenAvatar === avatar.iconIdx ? '3px solid white' : '3px solid transparent',
                borderRadius: '7rem'
              }}
              onClick={() => setChosenAvatar(avatar.iconIdx)}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-center'>
        <Button variant='secondary' className='px-4' onClick={() => onClose()}>Cancel</Button>
        <Button variant='primary' className='px-5' onClick={handleImageChange}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}