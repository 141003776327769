import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { PrivateRoute } from './components/PrivateRoute';
import HomePage from './pages/home';
import FaqPage from "./pages/faq";
import DonatePage from "./pages/donate";
import BotPage from "./pages/bot";
import AlertsPage from "./pages/alerts";
import SignPage from './pages/sign';
import MarketsPage from './pages/markets';
import NotFoundPage from './pages/404';
import AccountPage from './pages/account';
import APIPage from './pages/api';


function App() {

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/signin" element={<SignPage/>}/>

        <Route element={<PrivateRoute/>}>
          <Route path="/faq" element={<FaqPage/>}/>
          <Route path="/donate" element={<DonatePage/>}/>
          <Route path="/bots" element={<BotPage/>}/>
          <Route path="/alerts" element={<AlertsPage/>}/>
          <Route path="/account" element={<AccountPage/>}/>
          <Route path="/api" element={<APIPage/>}/>
          <Route path="/markets/:tradeTypeUrl/list" element={<MarketsPage/>}/>
        </Route>

        <Route index element={<HomePage/>}/>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/signup" element={<SignPage/>}/>
        <Route path="/restore_password/:hash" element={<SignPage/>}/>
        <Route path="*" element={<NotFoundPage/>}/>
      </Routes>
    </ErrorBoundary>
  );
}

export default App;