import { Col, Row, Stack } from "react-bootstrap";
import { getSupportIcon } from "../services/icons";
import React from "react";

export default function Support() {
  return (
    <Stack direction="vertical" gap={2} style={{marginTop: '115px'}}>
      <Row className='justify-content-end'>
        <Col md={8}>
          <div className='d-flex flex-column align-items-end'>
            <div className='tg-help px-4 py-3 mb-4'>If you have any questions about your API, please
              contact our support on Telegram&nbsp;<a
                href='tg://resolve?domain=@CopyMaster'>@CopyMaster</a>
            </div>
            <a
              href='tg://resolve?domain=@CopyMaster'>{getSupportIcon()}</a>
          </div>
        </Col>
      </Row>
    </Stack>
  )
}