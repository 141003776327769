import React, { FC, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from "react-router-dom";

import './ModalManageApi.scss';
import { ModalManageApiRow } from './ModalManageApiRow/ModalManageApiRow';
import { getCrossEyeIcon, getEyeIcon, getPencilIcon } from "../../../services/icons";
import useAPIStore from "../../../services/hooks/useFetchedDataStore";

interface Props {
  show: boolean,
  onClose: () => void,
  currentApi: any,
  activeTab: any
}

const defaultLogo = new URL("../../../assets/images/avatars/binance.png", import.meta.url);

export const ModalManageApi: FC<Props> = ({show, onClose, currentApi, activeTab}) => {

  const [currentAction, setCurrentAction] = useState('Add');
  const [currentTab, setCurrentTab] = useState(activeTab)
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [valueApiName, setValueApiName] = useState('');
  const [valueApiKey, setValueApiKey] = useState('');
  const [valueSecretKey, setValueSecretKey] = useState('');
  const [policyChecked, setPolicyChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    apiName: '',
    apiKey: '',
    apiSecretKey: '',
    checkbox: '',
    groupName: ''
  });
  const [visible, setVisible] = useState<boolean>(false);
  const [valueGroup, setValueGroup] = useState<string>(currentApi.groupName === null ? '' : currentApi.groupName);
  const [readOnly, setReadOnly] = useState<boolean>(currentApi.groupName === null ? false : true);
  const {
    fetchedData,
    fetchData
  } = useAPIStore();

  async function onClickAddSubApi(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    const data = {
      name: valueApiName,
      key: valueApiKey,
      secret: valueSecretKey,
      groupName: valueGroup,
      market: 'BINANCE',
      mainUid: currentApi.apiUid,
    };

    if (valueGroup.length < 1 || valueApiName.length < 1 || valueApiKey.length < 1 || valueSecretKey.length < 1 || !policyChecked) {
      setErrors({
        groupName: valueGroup.length < 1 ? 'Field should contain at least 1 character' : '',
        apiName: valueApiName.length < 1 ? 'Field should contain at least 1 character' : '',
        apiKey: valueApiKey.length < 1 ? 'Field should contain at least 1 character' : '',
        apiSecretKey: valueSecretKey.length < 1 ? 'Field should contain at least 1 character' : '',
        checkbox: !policyChecked ? 'You must agree to the usage policy' : ''
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/key/group', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });


      if (response.ok) {
        onClose();
        await fetchData();
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsLoading(false);
    }
  }

  const handleCheckboxChange = (uid: number, isChecked: boolean) => {
    setSelectedRows((prevSelectedRows) =>
      isChecked ? [...prevSelectedRows, uid] : prevSelectedRows.filter((id) => id !== uid)
    );
  };

  async function handleAddToGroup() {
    const data = currentAction === 'Add' ? {
      "market": "BINANCE",
      "groupName": valueGroup,
      "mainUid": currentApi.apiUid,
      "addUids": selectedRows
    } : {
      "market": "BINANCE",
      "groupName": valueGroup,
      "mainUid": currentApi.apiUid,
      "removeUids": selectedRows
    };


    if (valueGroup.length < 1) {
      setErrors({
        groupName: valueGroup.length < 1 ? 'Field should contain at least 1 character' : '',
        apiName: '',
        apiKey: '',
        apiSecretKey: '',
        checkbox: ''
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(currentAction === 'Add' ? '/api/key/group/add' : '/api/key/group/remove', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        await fetchData();
        onClose();
      } else {
        const errorData = await response.json();
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsLoading(false);
    }
  }

  function handleChange(event: any) {
    setValueGroup(event.target.value)
  }

  function handleKeyDown(event: any) {
    if (event.keyCode === 13) {
      setReadOnly(true)
    }
  }

  function handleBlur() {
    if (valueGroup.length > 1) {
      setReadOnly(true)
    }
  }

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' size='xl' fullscreen='sm-down' centered
           className='modal--manage-api'>
      <div className='tabs__switcher position-absolute d-flex'>
        {['Add Sub-API', 'Addiction'].map((item, index) =>
          <p
            key={index}
            className={classNames(`tab__switcher w-50 py-2 h-100 d-flex align-items-center justify-content-center position-relative ${index === 0 ? 'px-4' : 'px-3'} `, {active: item === currentTab})}
            onClick={() => setCurrentTab(item)}
          >
            {item}
          </p>
        )}
      </div>
      <Modal.Header className='d-flex justify-content-center pt-5' closeButton>
        <Modal.Title
          className='d-flex flex-column align-items-center'>
          <div className='d-flex flex-row align-items-center justify-content-between gap-4 roboto-bold pb-3'
               style={{fontSize: '20px'}}>
            {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={80} width={80}/>}
            Don't have an account on the exchange?
            <a className='btn btn-primary px-3 py-2' style={{borderRadius: '35px'}} target='_blank' rel='noreferrer'
               href='https://www.binance.com/activity/referral-entry/CPA?ref=CPA_00A7CU939I'> to registration</a>
          </div>
          {currentTab !== 'Remove' ? (readOnly ? <div className="d-flex gap-1 group-name">
              <div className="">{valueGroup}</div>
              <span className="" onClick={(event) => setReadOnly(false)}>{getPencilIcon()}</span></div> :
            <div><label className='d-flex align-items-center gap-2 group-input'> Group name :
              <input
                type="text"
                name='groupName'
                required
                placeholder=""
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                value={valueGroup}
                readOnly={readOnly}
              />
            </label>
              {errors.groupName && <div style={{color: 'red', fontSize: '12px'}}>{errors.groupName}</div>}
            </div>) : <div className="">{valueGroup}</div>
          }
          {currentTab === 'Addiction' ? 'Addiction' : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='mx-auto w-100 px-0 pt-0'>
        {currentTab === 'Addiction' ?
          <Stack direction='vertical' gap={4}>
            <div className='modal__switcher'>
              {['Remove', 'Add'].map((item, index) =>
                <p
                  key={index}
                  className={classNames('markets__switcher w-50 px-5 py-2', {active: item === currentAction})}
                  onClick={() => setCurrentAction(item)}
                >
                  {item}
                </p>
              )}
            </div>

            <Container className='px-0'>
              <Row className='modal__table-row align-items-center mb-4 mx-0'>
                <Col xs={12}>
                  <Row>
                    <Col xs={4} className='fw-bold'>
                      <div style={{paddingLeft: '1.5rem'}}>{currentApi.apiName}</div>
                    </Col>
                    <Col xs={3} className='ps-4'>
                      <Stack direction='horizontal' gap={2} className=' align-items-end'>
                        <span style={{color: '#9c9fa4', fontSize: '0.8rem'}}>Market:</span> Binance
                      </Stack>
                    </Col>
                    <Col xs={4} className='ps-4 pe-0'>
                      <Stack direction='horizontal' gap={2} className='align-items-center flex-wrap'>
                        <span style={{color: '#9c9fa4', fontSize: '0.8rem'}}>Balance:</span>
                        <span className='fw-bold' style={{color: '#36FF6C'}}>{currentApi.balance}</span>
                        USDT
                      </Stack>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className='modal__maxrows'>
                {currentAction === 'Add' ? fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups.filter((api: any) => api.mainKey.uid !== currentApi.apiUid && api.copyKeys.length === 0).map((item: any, index: number) => {
                    return <ModalManageApiRow
                      key={index}
                      balance={item.mainKey.usdtBalance}
                      user={item.mainKey.name}
                      uid={item.mainKey.uid}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  }) :
                  currentApi.group.map((item: any, index: number) => {
                    return <ModalManageApiRow
                      key={index}
                      balance={item.usdtBalance}
                      user={item.name}
                      uid={item.uid}
                      onCheckboxChange={handleCheckboxChange}
                    />
                  })
                }
              </div>
            </Container>
          </Stack> :
          <Stack direction='horizontal' gap={3}>
            <Stack className="flex-row align-items-center justify-content-center">
              {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={222} width={222}/>}
            </Stack>
            <Stack direction='vertical' className='p-4 text-white' gap={2}>
              <div className='pb-2 h4 modal-title'>Add Sub-API</div>
              <form>
                <Row className='align-items-center pb-3'>
                  <Col className="max-fit">API name</Col>
                  <Col className='text-white fw-bold'>
                    <div className='alerts-table__inputwrapper-modal p-0'
                         style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                      <input
                        type='text'
                        name='apiName'
                        value={valueApiName}
                        required
                        size={20}
                        onChange={(event) => setValueApiName(event.target.value)}
                        style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                        className='px-4 w-100 h-100 flex align-items-center input'
                      />
                      {errors.apiName && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiName}</div>}
                    </div>
                  </Col>
                </Row>
                <Row className='align-items-center pb-3'>
                  <Col className="max-fit">API key</Col>
                  <Col className='text-white fw-bold'>
                    <div className='alerts-table__inputwrapper-modal p-0'
                         style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                      <input
                        type='text'
                        name='apiKey'
                        value={valueApiKey}
                        required
                        size={20}
                        style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                        onChange={(event) => setValueApiKey(event.target.value)}
                        className='px-4 w-100 h-100 flex align-items-center input'
                      />
                      {errors.apiKey && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiKey}</div>}
                    </div>
                  </Col>
                </Row>
                <Row className='align-items-center pb-2'>
                  <Col className="max-fit">Secret key</Col>
                  <Col className='text-white fw-bold'>
                    <div className='alerts-table__inputwrapper-modal p-0 position-relative'
                         style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                      <input
                        type={visible ? 'text' : 'password'}
                        name='apiSecretKey'
                        value={valueSecretKey}
                        required
                        size={20}
                        style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                        onChange={(event) => setValueSecretKey(event.target.value)}
                        className='px-4 w-100 h-100 flex align-items-center input '
                      />
                      {errors.apiSecretKey && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiSecretKey}</div>}
                      <div className="position-absolute" onClick={() => setVisible(!visible)}
                           style={{right: '15px', top: '10px'}}>{visible ? getEyeIcon() : getCrossEyeIcon()}</div>
                    </div>
                  </Col>
                </Row>
                <Row className='pt-4'>
                  <Col>
                    <Stack direction='horizontal' gap={2}>
                      <Form.Check
                        id='testId'
                        name='usagePolicy'
                        required
                        onChange={(event) => setPolicyChecked(event.target.checked)}
                        style={{border: '1px solid #7B7C85', borderRadius: '2px'}}
                        className='api-checkbox d-flex'
                      />
                      I have read the <Link to='#' style={{color: '#3548FE'}}>Usage Policy</Link>
                    </Stack>
                    {errors.checkbox && <div style={{color: 'red', fontSize: '12px'}}>{errors.checkbox}</div>}
                  </Col>
                </Row>
                <Row className='justify-content-center pt-3 pb-0'>
                  <Col gap={2}>
                    <Stack direction='horizontal' gap={3}>
                      <Button variant='secondary' className='px-5' style={{borderRadius: '35px'}}
                              onClick={() => onClose()}>Decline</Button>
                      <Button variant='primary' className='px-5' style={{borderRadius: '35px'}} disabled={isLoading}
                              onClick={onClickAddSubApi}> {isLoading ? 'Saving...' : 'Save'}</Button>
                    </Stack>
                  </Col>
                </Row>
              </form>
            </Stack>
          </Stack>
        }
      </Modal.Body>
      <Modal.Footer className='justify-content-center pb-5'>
        {currentTab === 'Addiction' && <Stack direction='horizontal' gap={3}>
            <Button style={{borderRadius: '35px'}} variant='secondary' className='px-4 py-2' onClick={onClose}>Reset
                changes</Button>
            <Button style={{borderRadius: '35px'}} variant='primary' className='px-5 py-2' disabled={isLoading}
                    onClick={handleAddToGroup}>{isLoading ? 'Saving...' : 'Save'}</Button>
        </Stack>}
      </Modal.Footer>
    </Modal>
  )
}