import { create } from 'zustand';
import { BalanceTypeBody, OrderData } from "../../types/types";

interface GroupInfo {
  inGroup: boolean;
}

interface RemoveOrderData {
  blockId: number;
}

interface StoreState {
  userBalance: BalanceTypeBody | null;
  setUserBalance: (balance: BalanceTypeBody) => void;
  newOrder: Record<number, OrderData[]>;
  addNewOrder: (blockId: number, orderData: OrderData) => void;
  filledOrder: Record<number, OrderData[]>;
  addFilledOrder: (blockId: number, orderData: OrderData) => void;
  clearFilledOrder: () => void;
  addChildOrder: (blockId: number, childOrderData: OrderData) => void;
  removeOrder: RemoveOrderData | null;
  setRemoveOrder: (removeOrder: RemoveOrderData | null) => void;
  groupInfo: GroupInfo | null;
  setGroupInfo: (groupInfo: GroupInfo | null) => void;
}

const useNewOrderStore = create<StoreState>((set) => ({
  userBalance: null,
  setUserBalance: (balance) => set({userBalance: balance}),
  newOrder: {},
  addNewOrder: (blockId, orderData) => set((state) => ({
    newOrder: {
      ...state.newOrder,
      [blockId]: [...(state.newOrder[blockId] || []), orderData],
    },
  })),
  filledOrder: {},
  addFilledOrder: (blockId, orderData) => set((state) => ({
    filledOrder: {
      ...state.filledOrder,
      [blockId]: [...(state.filledOrder[blockId] || []), orderData],
    },
  })),
  clearFilledOrder: () => set({filledOrder: {}}),
  addChildOrder: (blockId, childOrderData) => set((state) => ({
    newOrder: {
      ...state.newOrder,
      [blockId]: [...(state.newOrder[blockId] || []), childOrderData],
    },
  })),
  removeOrder: null,
  setRemoveOrder: (removeOrder) => set({removeOrder: removeOrder, newOrder: {}}),
  groupInfo: null,
  setGroupInfo: (groupInfo) => set({groupInfo: groupInfo}),
}));

export default useNewOrderStore;