import React, { FC, useState } from 'react';
import { Col, Collapse, Form, Row, Stack } from 'react-bootstrap';

interface Props {
  item: any;
  selectedRow: number | null;
  handleCheckboxChange: (uid: number) => void;
}

export const ModalManageActiveApiRow: FC<Props> = ({item, selectedRow, handleCheckboxChange}) => {
  const [openRepeatAccounts, setOpenRepeatAccounts] = useState(false);

  return (
    <div>
      <Row
        aria-controls={item?.mainKey?.uid}
        aria-expanded={openRepeatAccounts}
        style={{cursor: 'pointer', height: '3rem'}}
        onClick={() => setOpenRepeatAccounts(!openRepeatAccounts)}
        className={`${item.copyKeys && item.copyKeys.length > 0 ? 'group-row mt-2' : 'mt-2'}`}

      >
        <Col xs={11} className='pe-4'>
          <Row className='modalmanageapi__row align-items-center h-100'>
            <Col xs={5}>
              <div style={{paddingLeft: '1.5rem'}}
                   className='text-start'>{item.mainKey ? item.mainKey.name : item.name}</div>
            </Col>
            <Col xs={3} style={{fontSize: '0.8rem'}}><span
              style={{
                color: '#9c9fa4',
                fontSize: '0.65rem'
              }}>Market:</span> {item.mainKey ? item.mainKey.market : item.market}</Col>
            <Col xs={4}>
              <Stack direction='horizontal' gap={1} className='align-items-center'
                     style={{fontSize: '0.8rem'}}>
                <span style={{color: '#9c9fa4', fontSize: '0.65rem'}}>Balance:</span>
                <span className='fw-bold'
                      style={{color: '#36FF6C'}}>{item.mainKey ? item.mainKey.usdtBalance : item.usdtBalance} USDT</span>
                {item.mainKey ? item.mainKey.currency : ''}
              </Stack>
            </Col>
          </Row>
        </Col>
        <Col className='modalmanageapi__row d-flex align-items-center justify-content-center h-100'
             style={{maxWidth: '62px'}}>
          <Form.Check
            type='checkbox'
            name={`checkbox-${item.mainKey ? item.mainKey.uid : item.uid}`}
            checked={item.mainKey ? selectedRow === item.mainKey.uid : selectedRow === item.uid}
            onChange={() => handleCheckboxChange(item.mainKey ? item.mainKey.uid : item.uid)}
          />
        </Col>
      </Row>
      {item.copyKeys && item.copyKeys.length > 0 &&
          <div
              aria-controls={item?.mainKey?.uid}
              aria-expanded={openRepeatAccounts}
              className={`${item.copyKeys.length > 0 ? 'group-subrow' : ''}`}
              style={{
                minHeight: '1rem',
                cursor: 'pointer',
                backgroundColor: '#161822',
                borderBottomLeftRadius: '20px',
                borderBottomRightRadius: '20px'
              }}>
              <Collapse in={openRepeatAccounts}>
                  <Stack direction="vertical" gap={5} id={item?.mainKey?.uid} className=''>
                    {item?.copyKeys.map((i: any, index: number) => {

                      return <Row className='mt-0' key={index}>
                        <Col xs={1}></Col>
                        <Col xs={11} className='px-0 pt-2'>
                          <Row className='pt-2 align-items-center'>
                            <Col xs={4}>
                              <div style={{paddingLeft: '1.5rem'}} className='text-start'>{i.name}</div>
                            </Col>
                            <Col xs={4} style={{fontSize: '0.8rem'}}><span
                              style={{color: '#9c9fa4', fontSize: '0.65rem'}}>Market:</span> {i.market}</Col>
                            <Col xs={4}>
                              <Stack direction='horizontal' gap={1} className='align-items-center'
                                     style={{fontSize: '0.8rem'}}>
                                <span style={{color: '#9c9fa4', fontSize: '0.65rem'}}>Balance:</span>
                                <span className='fw-bold' style={{color: '#36FF6C'}}>{i.usdtBalance} USDT</span>
                              </Stack>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    })}
                  </Stack>
              </Collapse>
              <div className='d-flex justify-content-center align-items-center'
                   style={{paddingBottom: '20px', cursor: 'pointer'}}></div>
          </div>}
    </div>
  )
}

