import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row, Stack } from 'react-bootstrap';
import { toast } from "react-toastify";

import './UserAccount.scss';
import { avatars } from "../../assets/data/datalist";
import { ModalChangeProfilePhoto } from '../Modals/ModalChangeProfilePhoto/ModalChangeProfilePhoto';
import { getPencilIcon, getTrashIcon } from "../../services/icons";
import useFocus from "../../hooks/useFocus";
import useAuth from '../../hooks/useAuth';
import useAPIStore from "../../services/hooks/useFetchedDataStore";
import useUserStore from "../../services/hooks/useUserStore";
import { UserApiCard } from "./UserApiCard/UserApiCard";
import { UserApiSubCard } from "./UserApiSubCard/UserApiSubCard";
import { ModalAddApi } from "../Modals/ModalAddApi/ModalAddApi";
import { ModalManageApi } from "../Modals/ModalManageApi/ModalManageApi";

type UserFields = 'username' | 'telegram' | 'password';

export const UserAccount = () => {
  const [tabApiName, setTabApiName] = useState('Addiction');
  const [showModalAddAlert, setShowModalAddAlert] = useState(false);
  const {userInfo, fetchUserData, updateUserData} = useUserStore();
  const [openSubAccounts, setOpenSubAccounts] = useState(false);
  const [openRepeatAccounts, setOpenRepeatAccounts] = useState(false);
  const [openRepeatSubAccounts, setOpenRepeatSubAccounts] = useState(false);
  const [showModalChangeProfilePhoto, setShowModalChangeProfilePhoto] = useState(false);
  const [showManageApiModal, setManageApiModal] = useState(false);
  const [inputValues, setInputValues] = useState({username: '', telegram: '', password: ''});
  const [inputReadonly, setInputReadonly] = useState<{ [key: string]: boolean }>({
    username: true,
    telegram: true,
    password: true
  });

  const [manageApi, setManageApi] = useState({
    apiName: '',
    apiUid: 0,
    balance: 0,
    group: [],
    groupName: ''
  });

  const {fetchedData, fetchData,} = useAPIStore();

  const [usernameRef, setUsernameFocus] = useFocus<HTMLInputElement>();
  const [telegramRef, setTelegramFocus] = useFocus<HTMLInputElement>();
  const [passwordRef, setPasswordFocus] = useFocus<HTMLInputElement>();
  const {isAuthenticated, setAuth} = useAuth();

  function handleManageAPI(e: any) {
    setManageApiModal(true)
    setManageApi({
      apiName: fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup?.mainKey?.name,
      apiUid: fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup?.mainKey?.uid,
      balance: fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup?.mainKey?.usdtBalance,
      group: fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup?.copyKeys,
      groupName: fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.groupName
    });
    setTabApiName('Add Sub-API')
  }

  const handleRepeatAccountsToggle = (isOpen: boolean) => {
    setOpenRepeatAccounts(isOpen);
  };

  const handleRepeatSubAccountsToggle = (isOpen: boolean) => {
    setOpenRepeatSubAccounts(isOpen);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEdit = (fieldName: any) => {
    setInputReadonly((prevReadonly) => ({
      ...prevReadonly,
      [fieldName]: false,
    }));
    if (fieldName === 'username') {
      setUsernameFocus();
    } else if (fieldName === 'telegram') {
      setTelegramFocus();
    } else if (fieldName === 'password') {
      setPasswordFocus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, fieldName: UserFields) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (fieldName === 'password') {
        setInputReadonly((prevReadonly) => ({
          ...prevReadonly,
          password: true,
          passwordConfirmation: true,
        }));
        updateUserData(fieldName, inputValues[fieldName]);
        setInputValues((prevValues) => ({
          ...prevValues,
          password: '', // Clear the password field
        }));
      } else {
        setInputReadonly((prevReadonly) => ({
          ...prevReadonly,
          [fieldName]: true,
        }));
        updateUserData(fieldName, inputValues[fieldName]);
      }
    }
  };

  async function addAPi() {
    await fetchData();
  }

  const deleteUser = async () => {
    try {
      await fetch(`/api/user`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setAuth(false)
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData, showModalChangeProfilePhoto]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  useEffect(() => {
    if (userInfo && userInfo.body) {
      setInputValues({
        username: userInfo.body.username,
        telegram: userInfo.body.telegram,
        password: "",
      });
    }
  }, [userInfo]);

  const avatar = avatars.filter((avatar: any) => avatar.iconIdx === userInfo?.body.iconIdx)

  return (
    <main className='account my-5 py-5 px-3 px-md-5'>
      <Stack direction="horizontal" gap={5} className='justify-content-end justify-content-lg-between my-3 flex-wrap'>
        <Row className='w-100'>
          <Col xs={3}>
            <Stack direction="vertical" gap={4} className='align-items-center'>
              <div style={{width: '263px', height: '263px'}}>
                {avatar[0]?.logo.href && <img src={avatar[0]?.logo.href} alt="avatar" width={263} height={263}/>}
              </div>
              <p style={{color: '#8997DC', cursor: 'pointer'}}
                 onClick={() => setShowModalChangeProfilePhoto(true)}>Change
                profile photo</p>

              <div className='account__bgfield text-center'>
                <p>Add API - key</p>
                <button
                  className='header__button header__button--fill fw-bold mt-3 py-2'
                  onClick={() => setShowModalAddAlert(true)}
                >Add
                </button>
              </div>

            </Stack>
          </Col>
          <Col xs={9}>
            <Stack direction="vertical" gap={3}>
              <hr className='account__hr'/>
              <Stack direction="vertical" gap={3} className='mx-3'>
                <div className='d-flex justify-content-between'>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      value={inputValues.username || ''}
                      onChange={handleInputChange}
                      onKeyUp={(e) => handleKeyPress(e, 'username')}
                      className='bg-transparent border-0 color-white name h4 account__input acccount__grey'
                      readOnly={inputReadonly.username}
                      ref={usernameRef}
                      name='username'
                    />
                    <button
                      type='button'
                      className='bg-transparent'
                      onClick={() => handleEdit('username')}
                    >{getPencilIcon('1.1rem', '1.1rem')}</button>
                  </div>
                  <button type='button' onClick={deleteUser} className='bg-transparent'>{getTrashIcon()}</button>
                </div>
                <Row>
                  <Col xs={3}>ID:</Col>
                  <Col xs={9} className='account__grey'>1763876</Col>
                </Row>
                <Row>
                  <Col xs={3}>E-mail:</Col>
                  <Col xs={9} className='account__grey'>
                    <input
                      type="text"
                      value={userInfo?.body.email}
                      className='bg-transparent border-0 account__input account__grey'
                      readOnly
                      name='email'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>Telegram:</Col>
                  <Col xs={9} className='account__grey'>
                    <input
                      type="text"
                      value={inputValues.telegram || ''}
                      onChange={handleInputChange}
                      onKeyUp={(e) => handleKeyPress(e, 'telegram')}
                      className='bg-transparent border-0 account__input account__grey'
                      readOnly={inputReadonly.telegram}
                      ref={telegramRef}
                      name='telegram'
                    />
                    <button
                      type='button'
                      className='bg-transparent'
                      onClick={() => handleEdit('telegram')}
                    >{getPencilIcon('0.8rem', '0.8rem')}</button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>Password:</Col>
                  <Col xs={9} className='account__grey'>
                    <input
                      type="text"
                      placeholder="*********"
                      value={inputValues.password || ''}
                      onChange={handleInputChange}
                      onKeyUp={(e) => handleKeyPress(e, 'password')}
                      className='bg-transparent border-0 account__input account__grey'
                      readOnly={inputReadonly.password}
                      ref={passwordRef}
                      name='password'
                    />
                    <button
                      type='button'
                      className='bg-transparent'
                      onClick={() => handleEdit('password')}
                    >{getPencilIcon('0.8rem', '0.8rem')}</button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>Two-factor authentication:</Col>
                  <Col xs={9} style={{color: '#8997DC'}} className='justify-content-end d-flex'>Turn ON</Col>
                </Row>
              </Stack>

              <hr className='account__hr'/>
              {console.log(fetchedData)}
              {fetchedData?.apiKeyMarketGroups && fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey && (
                <>
                  <UserApiCard
                    openRepeatAccounts={openRepeatAccounts}
                    onRepeatAccountsToggle={handleRepeatAccountsToggle}
                    data={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey}
                    group={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.copyKeys}
                    active={true}
                  />
                  {fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.copyKeys && fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.copyKeys.length > 0 &&
                      <Collapse in={openRepeatAccounts}>
                          <Stack direction="vertical" gap={5} id='collapse-repeat-accounts' className='mt-5'>
                            {fetchedData?.apiKeyMarketGroups && fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.copyKeys.map((item: any, index: number) => {
                              return <UserApiSubCard
                                data={item}
                                groupName={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.groupName}
                                mainApi={fetchedData?.apiKeyMarketGroups[0]?.activeMainApiKeyGroup.mainKey.uid}
                                key={item.uid}
                              />
                            })}
                          </Stack>
                      </Collapse>}
                </>
              )}
              {fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups && fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups.length > 0 && (
                <>
                  <Stack direction="vertical" gap={3} className='mx-3 mb-3'>
                    <div
                      onClick={() => setOpenSubAccounts(!openSubAccounts)}
                      aria-controls='collapse-sub-accounts'
                      aria-expanded={openSubAccounts}
                      style={{cursor: 'pointer', textAlign: 'center'}}
                    >
                      <h4>
                        Sub-accounts
                        {' '}
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 23 16"
                             fill="none">
                          <path
                            d="M21 2L12.7111 13.3427C12.1119 14.1627 10.8881 14.1627 10.2889 13.3427L2 2"
                            stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        </svg>
                      </h4>
                    </div>

                    <Collapse in={openSubAccounts}>
                      <Stack direction="vertical" gap={3} id='collapse-sub-accounts'>
                        {fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups && fetchedData?.apiKeyMarketGroups[0]?.apiKeyGroups.map((item: any, index: number) => {
                          return <>
                            <UserApiCard
                              openRepeatAccounts={openRepeatSubAccounts}
                              onRepeatAccountsToggle={handleRepeatSubAccountsToggle}
                              data={item.mainKey}
                              group={item.copyKeys}
                              active={false}
                              key={index}
                            />
                            {item.copyKeys && item.copyKeys.length > 0 &&
                                <Collapse in={openRepeatSubAccounts}>
                                    <Stack direction="vertical" gap={5} id='collapse-repeat-accounts' className='mt-5'>
                                      {item.copyKeys.map((key: any, index: number) => {
                                        return <UserApiSubCard
                                          data={key}
                                          groupName={key.groupName}
                                          mainApi={key.uid}
                                          key={key.uid}
                                        />
                                      })}
                                    </Stack>
                                </Collapse>}
                          </>
                        })}
                      </Stack>
                    </Collapse>
                  </Stack>

                  <Stack direction='horizontal' className='account__bgfield'>

                    <Stack direction="vertical" gap={3}>
                      <h4>Add API-key to repeat your deals</h4>
                    </Stack>
                    <button className='header__button header__button--fill fw-bold px-5'
                            onClick={handleManageAPI}>Add
                    </button>


                  </Stack>
                </>
              )}
            </Stack>
          </Col>
        </Row>
      </Stack>
      {showModalAddAlert &&
          <ModalAddApi
              show={showModalAddAlert}
              onClose={() => setShowModalAddAlert(false)}
              handler={addAPi}
          />
      }
      {showModalChangeProfilePhoto &&
          <ModalChangeProfilePhoto
              onClose={() => setShowModalChangeProfilePhoto(false)}
              show={showModalChangeProfilePhoto}
          />}

      {showManageApiModal &&
          <ModalManageApi
              show={showManageApiModal}
              onClose={() => setManageApiModal(false)}
              currentApi={manageApi}
              activeTab={tabApiName}
          />
      }
    </main>
  );
};