import React, { FC, useContext, useEffect, useState } from 'react';
import bigDecimal from 'js-big-decimal';

import { MarketsTabsType } from '../../../types/enums';
import MarketPriceProvider from '../../../context/MarketPriceProvider';
import { MarketsTableRowFirstPosition } from '../MarketsTableRowFirstPosition/MarketsTableRowFirstPosition';
import { MarketsTableRowSecondPosition } from '../MarketsTableRowSecondPosition/MarketsTableRowSecondPosition';
import { MarketsTableRowThirdPosition } from "../MarketsTableRowThirdPosition/MarketsTableRowThirdPosition";
import { MarketsTableRowFourthPosition } from "../MarketsTableRowFourthPosition/MarketsTableRowFourthPosition";
import { CalculateMarketBuyData } from "../Calculation/CalculateBuyValues";
import { CalculateMarketSellData } from "../Calculation/CalculateSellValues";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";

interface Props {
  data: any,
  counterEarning: boolean,
  tabType: MarketsTabsType,
}

export const MarketsTableRows: FC<Props> = ({data, counterEarning, tabType}) => {

  const [marketPrice, setMarketPrice] = useState(0);
  const [controlValue, setControlValue] = useState("");
  const [groupData, setGroupData] = useState([]);
  const {marketPriceContext} = useContext(MarketPriceProvider);
  const marketPriceDecimal = new bigDecimal(controlValue && controlValue.length > 0 ? controlValue : marketPriceContext);

  const {groupInfo, setGroupInfo} = useNewOrderStore();

  // function handleChangeControl(event: ChangeEvent<HTMLInputElement>) {
  //   let newValue = event.target.value;
  //
  //   newValue = newValue.replace(/,/g, '.');
  //
  //   const scale = data[0].counterRound ? Number(data[0].counterRound) : 2;
  //
  //   const [integerPart, decimalPart] = newValue.split('.');
  //   if (decimalPart && decimalPart.length > scale) {
  //     newValue = `${integerPart}.${decimalPart.slice(0, scale)}`;
  //   }
  //
  //   newValue = newValue.replace(/[^\d.]/g, '');
  //
  //   const dotCount = newValue.split('.').length - 1;
  //   if (dotCount > 1) {
  //     const lastDotIndex = newValue.lastIndexOf('.');
  //     newValue = newValue.slice(0, lastDotIndex) + newValue.slice(lastDotIndex + 1);
  //   }
  //   setControlValue(newValue);
  // }
  // console.log(data)
  const buyResults = CalculateMarketBuyData(data, counterEarning, marketPriceDecimal);
  const sellResults = CalculateMarketSellData(data, counterEarning, marketPriceDecimal);

  useEffect(() => {
    if (marketPriceContext !== undefined && marketPriceContext !== null) {
      setMarketPrice(marketPriceContext);
    }
  }, [marketPriceContext]);

  useEffect(() => {
    setGroupInfo(null);
  }, [setGroupInfo]);

  useEffect(() => {
    data.length > 1 || groupInfo ? setGroupData(data) : setGroupData([]);
  }, [data, tabType, counterEarning]);

  return (
    <div className='mt-4'> {tabType !== 'history' && data[0].blockId}
      {/*<div>*/}
      {/*  <input*/}
      {/*    type="text" className="markets-table__input "*/}
      {/*    value={controlValue} onChange={handleChangeControl}*/}
      {/*    style={{border: '1px solid #545d88', width: '300px', margin: '20px'}}*/}
      {/*    name="controlInput"*/}
      {/*    pattern='[0-9]*\.{0,1}[0-9]{0,8}'*/}
      {/*  />*/}
      {/*</div>*/}
      {tabType !== 'history' && <MarketsTableRowFirstPosition
          data={data[0]}
          counterEarning={counterEarning}
          tabType={data[0].section.toLowerCase()}
          value={
            tabType === MarketsTabsType.sell ? Number(sellResults.valueFirst.getValue()) > data[0].baseQuantityStep ? Number(sellResults.valueFirst.getValue()) : 0 :
              Number(buyResults.valueFirst.getValue()) > data[0].baseQuantityStep ? Number(buyResults.valueFirst.getValue()) : 0}
          show={buyResults.showFirstPosition}
          groupData={groupData}
          marketPrice={controlValue && controlValue.length > 0 ? Number(controlValue) : marketPriceContext}
      />}

      {tabType !== 'history' && <MarketsTableRowSecondPosition
          data={data[0]}
          counterEarning={counterEarning}
          tabType={data[0].section.toLowerCase()}
          averageQuantity={tabType === MarketsTabsType.sell ? sellResults.averageQuantitySecond : buyResults.averageQuantitySecond}
          profitValue={tabType === MarketsTabsType.sell ? Number(sellResults.profitQuantity.getValue()) : Number(buyResults.profitQuantity.getValue())}
          profitPercent={tabType === MarketsTabsType.sell ? sellResults.profitPercentSecond : buyResults.profitPercentSecond}
          groupData={groupData}
          marketPrice={controlValue && controlValue.length > 0 ? Number(controlValue) : marketPriceContext}
          value={tabType === MarketsTabsType.sell ? sellResults.valueSecond.getValue() : buyResults.valueSecond.getValue()}
          show={tabType === MarketsTabsType.sell ? sellResults.showSecondPosition : buyResults.showSecondPosition}
      />}

      {tabType !== 'history' && <MarketsTableRowThirdPosition
          data={data[0]}
          counterEarning={counterEarning}
          tabType={data[0].section.toLowerCase()}
          averageQuantity={tabType === MarketsTabsType.sell ? sellResults.averageQuantityThird : buyResults.averageQuantityThird}
          profitValue={tabType === MarketsTabsType.sell ? sellResults.profitTakeProfitStopLossValue : buyResults.profitTakeProfitStopLossValue}
          profitPercent={tabType === MarketsTabsType.sell ? sellResults.profitTakeProfitStopLossPercent.getValue() : buyResults.profitTakeProfitStopLossPercent.getValue()}
          value={tabType === MarketsTabsType.sell ?
            sellResults.valueThird.compareTo(new bigDecimal(sellResults.valueFourth)) !== 0 ? sellResults.valueThird.subtract(new bigDecimal(sellResults.valueFourth)).getValue() : new bigDecimal('0').getValue() :
            buyResults.valueThird.compareTo(new bigDecimal(buyResults.valueFourth)) !== 0 ? buyResults.valueThird.subtract(new bigDecimal(buyResults.valueFourth)).getValue() : new bigDecimal('0').getValue()}
          marketPrice={controlValue && controlValue.length > 0 ? Number(controlValue) : marketPriceContext}
          groupData={groupData}
          show={buyResults.showThirdPosition}
      />}


      <MarketsTableRowFourthPosition
        data={data[0]}
        counterEarning={counterEarning}
        tabType={data[0].section.toLowerCase()}
        averageQuantity={tabType === MarketsTabsType.sell ? sellResults.averageQuantityFourth : buyResults.averageQuantityFourth}
        profitValue={tabType === MarketsTabsType.sell ? sellResults.profitFilledTakeProfitStopLossValue : buyResults.profitFilledTakeProfitStopLossValue}
        profitPercent={tabType === MarketsTabsType.sell ? sellResults.profitFilledTakeProfitStopLossValuePercent.getValue() : buyResults.profitFilledTakeProfitStopLossValuePercent.getValue()}
        value={tabType === MarketsTabsType.sell ? sellResults.valueFourth : buyResults.valueFourth}
        show={tabType === MarketsTabsType.sell ? sellResults.showFourthPosition : buyResults.showFourthPosition}
        marketPrice={controlValue && controlValue.length > 0 ? Number(controlValue) : marketPriceContext}
        groupData={groupData}
        isFilled
      />

    </div>
  );
};