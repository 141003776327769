import React from "react";

export const getLogoIcon = (
  styleWidth = '5rem',
  styleHeight = '5rem'
) => {
  return (
    <svg
      style={{width: styleWidth, height: styleHeight}}
      viewBox="0 0 263 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="131.5" cy="131.5" r="131.5" fill="#3548FE"/>
      <path
        d="M203 140.563C201.789 151.45 198.046 161.018 191.771 169.266C185.497 177.404 177.24 183.728 167.002 188.237C156.875 192.746 145.206 195 131.995 195C117.574 195 104.97 192.306 94.1813 186.917C83.393 181.528 74.9715 173.995 68.9169 164.317C62.9723 154.639 60 143.367 60 130.5C60 117.633 62.9723 106.361 68.9169 96.6829C74.9715 87.0051 83.393 79.4719 94.1813 74.0831C104.97 68.6944 117.574 66 131.995 66C145.206 66 156.875 68.2545 167.002 72.7634C177.24 77.2724 185.497 83.6509 191.771 91.899C198.046 100.037 201.789 109.55 203 120.437H168.654C167.553 115.159 165.406 110.705 162.214 107.075C159.021 103.336 154.838 100.477 149.664 98.4974C144.6 96.5179 138.711 95.5281 131.995 95.5281C124.289 95.5281 117.629 96.9578 112.015 99.8171C106.401 102.566 102.107 106.581 99.1351 111.859C96.1628 117.028 94.6767 123.242 94.6767 130.5C94.6767 137.758 96.1628 144.027 99.1351 149.306C102.107 154.474 106.401 158.488 112.015 161.348C117.629 164.097 124.289 165.472 131.995 165.472C138.711 165.472 144.6 164.537 149.664 162.668C154.838 160.798 159.021 157.994 162.214 154.254C165.406 150.515 167.553 145.951 168.654 140.563H203Z"
        fill="white"/>
    </svg>
  )
}

export const getPencilIcon = (
  styleWidth = '0.8rem',
  styleHeight = '0.8rem',
  styleMarginLeft = '0.4rem'
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         style={{width: styleWidth, height: styleHeight, marginLeft: styleMarginLeft}} viewBox="0 0 15 15" fill="none">
      <path
        d="M5.43164 12.6562H2.8125C2.68818 12.6562 2.56895 12.6068 2.48105 12.5189C2.39314 12.431 2.34375 12.3118 2.34375 12.1874V9.56831C2.34354 9.50744 2.35533 9.44713 2.37846 9.39082C2.40158 9.33452 2.43558 9.28333 2.47852 9.24018L9.50977 2.20893C9.55338 2.16464 9.60538 2.12947 9.66271 2.10546C9.72005 2.08146 9.78159 2.06909 9.84375 2.06909C9.90591 2.06909 9.96745 2.08146 10.0248 2.10546C10.0821 2.12947 10.1341 2.16464 10.1777 2.20893L12.791 4.82221C12.8353 4.86583 12.8705 4.91782 12.8945 4.97516C12.9185 5.0325 12.9309 5.09404 12.9309 5.1562C12.9309 5.21836 12.9185 5.2799 12.8945 5.33724C12.8705 5.39458 12.8353 5.44657 12.791 5.49018L5.75977 12.5214C5.71662 12.5644 5.66543 12.5984 5.60913 12.6215C5.55282 12.6446 5.49251 12.6564 5.43164 12.6562V12.6562Z"
        stroke="#BEBFC3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.96875 3.75L11.25 7.03125" stroke="#BEBFC3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export const getCrossEyeIcon = (
  color = "#FFFFFF",
  width = "16",
  height = "20"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2614 0.73873L14.5226 0L11.3552 3.16745C10.2798 2.43799 8.9742 1.875 7.50016 1.875C5.60563 1.875 3.99036 2.805 2.7825 3.82313C1.56793 4.84594 0.690964 6.02156 0.260607 6.65625C0.0907859 6.90581 0.000159461 7.19908 0.000159461 7.49906C0.000159461 7.79905 0.0907859 8.09232 0.260607 8.34188C0.690964 8.9775 1.56793 10.1531 2.7825 11.1769C2.88322 11.2618 2.98679 11.3462 3.09313 11.4294L0 14.5226L0.738733 15.2613L15.2614 0.73873ZM4.1032 10.4194L5.91399 8.60859C5.82603 8.49089 5.75216 8.36324 5.69412 8.22812C5.59525 7.99793 5.54429 7.7507 5.54422 7.50095C5.54416 7.25119 5.595 7.00394 5.69375 6.77371C5.79251 6.54348 5.93719 6.33491 6.1193 6.16024C6.30141 5.98558 6.51729 5.84834 6.75425 5.75659C6.99121 5.66484 7.24449 5.62042 7.4992 5.62594C7.88081 5.63421 8.24831 5.75406 8.55639 5.96621L10.3337 4.18885C9.47356 3.64389 8.51727 3.28125 7.50016 3.28125C6.09146 3.28125 4.79848 3.97688 3.7178 4.88906C2.64287 5.79563 1.8491 6.85406 1.45509 7.43625C1.44193 7.45501 1.43488 7.47724 1.43488 7.5C1.43488 7.52276 1.44193 7.54499 1.45509 7.56375C1.84814 8.14688 2.64191 9.20437 3.7178 10.1109C3.84348 10.2169 3.97199 10.32 4.1032 10.4194ZM9.19593 8.28117L8.28775 9.18935C8.48599 9.09563 8.66806 8.96824 8.82477 8.81106C8.98013 8.65524 9.10508 8.47566 9.19593 8.28117ZM7.50016 11.7188C6.98712 11.7188 6.48956 11.6265 6.012 11.4651L4.92544 12.5516C5.70619 12.9006 6.5696 13.125 7.50016 13.125C9.39468 13.125 11.01 12.195 12.2178 11.1769C13.4324 10.1541 14.3094 8.97844 14.7397 8.34281C14.9095 8.09326 15.0002 7.79999 15.0002 7.5C15.0002 7.20001 14.9095 6.90674 14.7397 6.65719C14.4037 6.16089 13.7954 5.33537 12.9667 4.51044L11.9621 5.51499C12.6935 6.24652 13.2396 6.98466 13.5452 7.43625C13.5584 7.45501 13.5654 7.47724 13.5654 7.5C13.5654 7.52276 13.5584 7.54499 13.5452 7.56375C13.1512 8.14594 12.3574 9.20437 11.2825 10.1109C10.2018 11.0231 8.90886 11.7188 7.50016 11.7188Z"
        fill={color}
      />
    </svg>
  );
};

export const getEyeIcon = (
  color = "#FFFFFF",
  width = "16",
  height = "20"
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 12" fill="#8997DC">
      <path
        d="M8.00024 0C10.0211 0 11.745 0.992 13.0324 2.078C14.328 3.169 15.2634 4.423 15.7224 5.101C15.9036 5.36719 16.0002 5.68001 16.0002 6C16.0002 6.31999 15.9036 6.63281 15.7224 6.899C15.2634 7.577 14.328 8.831 13.0324 9.922C11.744 11.008 10.0211 12 8.00024 12C5.97942 12 4.25544 11.008 2.96807 9.922C1.67254 8.83 0.737102 7.576 0.278055 6.898C0.0969123 6.63181 0.000244141 6.31899 0.000244141 5.999C0.000244141 5.67901 0.0969123 5.36619 0.278055 5.1C0.737102 4.423 1.67254 3.169 2.96807 2.078C4.25646 0.992 5.97942 0 8.00024 0ZM1.55217 5.932C1.53813 5.95201 1.53061 5.97572 1.53061 6C1.53061 6.02428 1.53813 6.04799 1.55217 6.068C1.97143 6.69 2.81811 7.818 3.96573 8.785C5.11947 9.758 6.49763 10.5 8.00024 10.5C9.50286 10.5 10.882 9.758 12.0348 8.785C13.1814 7.818 14.028 6.689 14.4483 6.068C14.4624 6.04799 14.4699 6.02428 14.4699 6C14.4699 5.97572 14.4624 5.95201 14.4483 5.932C14.028 5.311 13.1814 4.182 12.0348 3.215C10.881 2.242 9.50286 1.5 8.00024 1.5C6.49763 1.5 5.11845 2.242 3.96573 3.215C2.81913 4.182 1.97245 5.311 1.55217 5.932ZM8.00024 8C7.72855 8.00602 7.45837 7.95877 7.20556 7.86103C6.95275 7.76328 6.72241 7.61701 6.52806 7.4308C6.33371 7.24458 6.17927 7.02218 6.0738 6.77666C5.96834 6.53113 5.91398 6.26742 5.91391 6.00101C5.91384 5.7346 5.96807 5.47087 6.07341 5.22529C6.17875 4.97971 6.33307 4.75723 6.52733 4.57093C6.72158 4.38462 6.95185 4.23823 7.20461 4.14036C7.45737 4.04249 7.72753 3.99511 7.99922 4.001C8.53229 4.01256 9.03956 4.22822 9.41245 4.60184C9.78533 4.97545 9.99419 5.47732 9.99433 6.00001C9.99446 6.5227 9.78585 7.02467 9.41316 7.39847C9.04047 7.77227 8.53331 7.98818 8.00024 8Z"
        fill={color}/>
    </svg>
  );
};

export const getSupportIcon = (
  width = "81",
  height = "81"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="40.5" cy="40.5" r="40.5" fill="#1E298E"/>
      <circle cx="40.5" cy="40.5" r="32.5" fill="#3548FE"/>
      <path
        d="M53.0684 38.5004H48.9434C48.3964 38.5004 47.8717 38.7177 47.485 39.1045C47.0982 39.4913 46.8809 40.0159 46.8809 40.5629V45.7192C46.8809 46.2662 47.0982 46.7908 47.485 47.1776C47.8717 47.5644 48.3964 47.7817 48.9434 47.7817H51.0059C51.5529 47.7817 52.0775 47.5644 52.4643 47.1776C52.8511 46.7908 53.0684 46.2662 53.0684 45.7192V38.5004Z"
        fill="white"/>
      <path
        d="M28.125 38.5004V45.7192C28.125 46.2662 28.3423 46.7908 28.7291 47.1776C29.1159 47.5644 29.6405 47.7817 30.1875 47.7817H32.25C32.797 47.7817 33.3216 47.5644 33.7084 47.1776C34.0952 46.7908 34.3125 46.2662 34.3125 45.7192V40.5629C34.3125 40.0159 34.0952 39.4913 33.7084 39.1045C33.3216 38.7177 32.797 38.5004 32.25 38.5004H28.125Z"
        fill="white"/>
      <path
        d="M53.0684 38.5004H48.9434C48.3964 38.5004 47.8717 38.7177 47.485 39.1045C47.0982 39.4913 46.8809 40.0159 46.8809 40.5629V45.7192C46.8809 46.2662 47.0982 46.7908 47.485 47.1776C47.8717 47.5644 48.3964 47.7817 48.9434 47.7817H51.0059C51.5529 47.7817 52.0775 47.5644 52.4643 47.1776C52.8511 46.7908 53.0684 46.2662 53.0684 45.7192V38.5004ZM53.0684 38.5004C53.0684 36.8666 52.7449 35.2489 52.1166 33.7408C51.4883 32.2326 50.5675 30.8638 49.4074 29.7133C48.2474 28.5628 46.8709 27.6535 45.3576 27.0377C43.8443 26.4219 42.224 26.1118 40.5902 26.1254C38.9575 26.1135 37.3386 26.4248 35.8268 27.0414C34.315 27.658 32.9401 28.5676 31.7814 29.7179C30.6227 30.8682 29.7031 32.2365 29.0755 33.7438C28.448 35.2511 28.125 36.8677 28.125 38.5004M28.125 38.5004V45.7192C28.125 46.2662 28.3423 46.7908 28.7291 47.1776C29.1159 47.5644 29.6405 47.7817 30.1875 47.7817H32.25C32.797 47.7817 33.3216 47.5644 33.7084 47.1776C34.0952 46.7908 34.3125 46.2662 34.3125 45.7192V40.5629C34.3125 40.0159 34.0952 39.4913 33.7084 39.1045C33.3216 38.7177 32.797 38.5004 32.25 38.5004H28.125Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M53.0684 45.7188V48.8125C53.0684 49.9065 52.6338 50.9557 51.8602 51.7293C51.0866 52.5029 50.0374 52.9375 48.9434 52.9375H41.5312"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>

  )
}

export const getTrashIcon = (
  color = "#FFFFFF",
  width = "18",
  height = "19"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45513 0.205025C8.31969 0.0737498 8.13599 0 7.94444 0H5.05556C4.86401 0 4.68031 0.0737498 4.54487 0.205025C4.40942 0.336301 4.33333 0.514348 4.33333 0.7C4.33333 0.885652 4.40942 1.0637 4.54487 1.19497C4.68031 1.32625 4.86401 1.4 5.05556 1.4H7.94444C8.13599 1.4 8.31969 1.32625 8.45513 1.19497C8.59058 1.0637 8.66667 0.885652 8.66667 0.7C8.66667 0.514348 8.59058 0.336301 8.45513 0.205025Z"
        fill={color}
      />
      <path
        d="M12.2778 2.1C12.4693 2.1 12.653 2.17375 12.7885 2.30503C12.9239 2.4363 13 2.61435 13 2.8C13 2.98565 12.9239 3.1637 12.7885 3.29497C12.653 3.42625 12.4693 3.5 12.2778 3.5H11.5556L11.5534 3.5497L10.8796 12.6994C10.8536 13.0526 10.6905 13.3832 10.4232 13.6245C10.1558 13.8658 9.80407 14 9.43872 14H3.56056C3.19521 14 2.84344 13.8658 2.57608 13.6245C2.30873 13.3832 2.14566 13.0526 2.11972 12.6994L1.44589 3.5504C1.44479 3.53362 1.44431 3.51681 1.44444 3.5H0.722222C0.530677 3.5 0.346977 3.42625 0.211534 3.29497C0.076091 3.1637 0 2.98565 0 2.8C0 2.61435 0.076091 2.4363 0.211534 2.30503C0.346977 2.17375 0.530677 2.1 0.722222 2.1H12.2778Z"
        fill={color}
      />
    </svg>
  )
}

export const getAddictionIcon = (
  color = "#FFFFFF",
) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <g clipPath="url(#clip0_832_3152)">
        <path
          d="M10.878 3.74992H1C0.801088 3.74992 0.610322 3.6709 0.46967 3.53025C0.329018 3.3896 0.25 3.19884 0.25 2.99992C0.25 2.80101 0.329018 2.61024 0.46967 2.46959C0.610322 2.32894 0.801088 2.24992 1 2.24992H10.878C11.0333 1.81123 11.3207 1.43145 11.7007 1.16284C12.0807 0.894229 12.5346 0.75 13 0.75C13.4654 0.75 13.9193 0.894229 14.2993 1.16284C14.6793 1.43145 14.9667 1.81123 15.122 2.24992H17C17.1989 2.24992 17.3897 2.32894 17.5303 2.46959C17.671 2.61024 17.75 2.80101 17.75 2.99992C17.75 3.19884 17.671 3.3896 17.5303 3.53025C17.3897 3.6709 17.1989 3.74992 17 3.74992H15.122C14.9667 4.18861 14.6793 4.5684 14.2993 4.83701C13.9193 5.10562 13.4654 5.24985 13 5.24985C12.5346 5.24985 12.0807 5.10562 11.7007 4.83701C11.3207 4.5684 11.0333 4.18861 10.878 3.74992ZM17 11.7499C17.1989 11.7499 17.3897 11.6709 17.5303 11.5303C17.671 11.3896 17.75 11.1988 17.75 10.9999C17.75 10.801 17.671 10.6102 17.5303 10.4696C17.3897 10.3289 17.1989 10.2499 17 10.2499H7.122C6.96673 9.81123 6.67931 9.43145 6.29929 9.16284C5.91928 8.89423 5.46536 8.75 5 8.75C4.53464 8.75 4.08072 8.89423 3.70071 9.16284C3.32069 9.43145 3.03327 9.81123 2.878 10.2499H1C0.801088 10.2499 0.610322 10.3289 0.46967 10.4696C0.329018 10.6102 0.25 10.801 0.25 10.9999C0.25 11.1988 0.329018 11.3896 0.46967 11.5303C0.610322 11.6709 0.801088 11.7499 1 11.7499H2.878C3.03281 12.1891 3.32006 12.5694 3.70013 12.8384C4.0802 13.1074 4.53436 13.2518 5 13.2518C5.46564 13.2518 5.9198 13.1074 6.29987 12.8384C6.67994 12.5694 6.96719 12.1891 7.122 11.7499H17Z"
          fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_832_3152">
          <rect width="18" height="14" fill={color}/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const getMagnifierIcon = (
  color = "#FFFFFF",
  className = ""
) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5.5" stroke={color}/>
      <path d="M9 10L13 14" stroke={color}/>
    </svg>
  )
}

export const getBarsIcon = (
  color = "#FFFFFF",
  className = ""
) => {
  return (
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="30.9608" cy="30" rx="29.9808" ry="30" fill="#182172"/>
      <rect x="34.5483" y="19.999" width="12.9547" height="15.5556" rx="3" fill="#377BFF"/>
      <rect x="39.7324" y="17.4082" width="2.59093" height="20.7407" rx="1.29547" fill="#377BFF"/>
      <rect x="21.5957" y="12.2217" width="2.59093" height="6.66667" rx="1.29547" fill="#377BFF"/>
      <rect x="21.5957" y="41.1016" width="2.59093" height="6.66667" rx="1.29547" fill="#377BFF"/>
      <mask id="path-6-inside-1_3821_1214" fill="white">
        <rect x="16.4136" y="17.4082" width="12.9547" height="25.9259" rx="3"/>
      </mask>
      <rect x="16.4136" y="17.4082" width="12.9547" height="25.9259" rx="3" stroke="#377BFF" strokeWidth="6.66667"
            mask="url(#path-6-inside-1_3821_1214)"/>
    </svg>
  )
}

export const getMoonIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_778_8626)">
        <rect width="60" height="60" rx="30" fill="#182172"/>
      </g>
      <path
        d="M50.7689 37.9857C46.7447 39.8059 42.2613 40.3567 37.9162 39.5646C33.571 38.7726 29.5704 36.6753 26.4473 33.5521C23.3241 30.429 21.2268 26.4284 20.4348 22.0832C19.6427 17.7381 20.1935 13.2547 22.0137 9.23047C18.8099 10.6783 16.0052 12.8818 13.8402 15.6518C11.6752 18.4218 10.2145 21.6757 9.58345 25.1344C8.95239 28.593 9.16981 32.1531 10.217 35.5092C11.2643 38.8654 13.1101 41.9173 15.5961 44.4033C18.0821 46.8893 21.134 48.7351 24.4902 49.7824C27.8463 50.8296 31.4064 51.047 34.865 50.4159C38.3237 49.7849 41.5776 48.3242 44.3476 46.1592C47.1176 43.9942 49.3211 41.1895 50.7689 37.9857Z"
        fill="#377BFF"/>
      <defs>
        <filter id="filter0_b_778_8626" x="-69.2308" y="-69.2308" width="198.462" height="198.462"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="34.6154"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_778_8626"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_778_8626" result="shape"/>
        </filter>
      </defs>
    </svg>

  )
}

export const getTelegramIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3821_1186)">
        <path
          d="M12.0094 24.0187C18.6419 24.0187 24.0187 18.6419 24.0187 12.0094C24.0187 5.37677 18.6419 0 12.0094 0C5.37677 0 0 5.37677 0 12.0094C0 18.6419 5.37677 24.0187 12.0094 24.0187Z"
          fill="#27A5E5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.42498 11.8785C8.92303 10.3446 11.2613 9.35321 12.4398 8.86685C15.7695 7.48259 16.4616 7.23941 16.9106 7.2207C17.0041 7.2207 17.2286 7.23941 17.3782 7.35165C17.4905 7.44518 17.5279 7.57612 17.5466 7.66965C17.5653 7.76318 17.584 7.96895 17.5653 8.1373C17.3782 10.0266 16.6113 14.647 16.1997 16.7608C16.0314 17.6587 15.6947 17.958 15.3767 17.9954C14.6845 18.0516 14.1421 17.5278 13.4686 17.0976C12.4211 16.4054 11.8225 15.9752 10.7936 15.3018C9.61516 14.5161 10.3821 14.0859 11.0555 13.3937C11.2239 13.2067 14.3104 10.4195 14.3665 10.1576C14.3665 10.1202 14.3852 10.0079 14.3104 9.9518C14.2356 9.89568 14.1421 9.91439 14.0672 9.9331C13.955 9.9518 12.2714 11.0742 8.99786 13.2815C8.5115 13.6182 8.08126 13.7679 7.68843 13.7679C7.25818 13.7679 6.43511 13.5247 5.81781 13.3189C5.06956 13.0757 4.47097 12.9448 4.52709 12.5333C4.5645 12.3275 4.8638 12.103 5.42498 11.8785Z"
              fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_3821_1186">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const getGmailIcon = () => {
  return (
    <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3821_1178)">
        <path d="M1.77748 19.9997H5.92493V9.71401L0 5.14258V18.1783C0 19.1783 0.801609 19.9997 1.77748 19.9997Z"
              fill="#4285F4"/>
        <path d="M20.1094 19.9997H24.2568C25.2327 19.9997 26.0343 19.1783 26.0343 18.1783V5.14258L20.1094 9.67829"
              fill="#34A853"/>
        <path d="M20.1094 1.82146V9.71432L26.0343 5.1786V2.71431C26.0343 0.464315 23.5249 -0.8214 21.7823 0.535743"
              fill="#FBBC04"/>
        <path d="M5.92578 9.71415V1.82129L13.0357 7.28557L20.1108 1.82129V9.71415L13.0008 15.1427" fill="#EA4335"/>
        <path d="M0 2.71419V5.14276L5.92493 9.67847V1.82133L4.25201 0.535618C2.50938 -0.78581 0 0.499904 0 2.71419Z"
              fill="#C5221F"/>
      </g>
      <defs>
        <clipPath id="clip0_3821_1178">
          <rect width="26" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const getInputCheckedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      cursor='pointer'
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM20.4885 10.3358C21.2262 9.51375 21.1579 8.24927 20.3358 7.51152C19.5138 6.77376 18.2493 6.84211 17.5115 7.66418L10.8327 15.1063L8.40218 12.7167C7.61454 11.9423 6.34825 11.953 5.57385 12.7407C4.79945 13.5283 4.81018 14.7946 5.59782 15.569L9.5209 19.4261C9.9104 19.8091 10.4396 20.0161 10.9855 19.999C11.5315 19.982 12.0467 19.7423 12.4116 19.3358L20.4885 10.3358Z"
        fill="#36FF6C"/>
    </svg>
  )
}

export const getInputIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      cursor='pointer'>
      <circle cx="13" cy="13" r="12.5" stroke="#36FF6C"/>
    </svg>
  )
}

export const getActiveIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="6" cy="6" r="6" fill="#36FF6C"/>
      <circle cx="6" cy="6" r="3" fill="#36FF6C"/>
    </svg>
  )
}

export const getCopyIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.84783 17H16.1522C16.377 17 16.5927 16.9107 16.7517 16.7517C16.9107 16.5927 17 16.377 17 16.1522V4.84783C17 4.62297 16.9107 4.40732 16.7517 4.24832C16.5927 4.08932 16.377 4 16.1522 4H4.56522C4.41531 4 4.27155 4.05955 4.16555 4.16555C4.05955 4.27155 4 4.41531 4 4.56522V16.1522C4 16.377 4.08932 16.5927 4.24832 16.7517C4.40732 16.9107 4.62297 17 4.84783 17ZM5.71739 16H15.2826C15.4729 16 15.6553 15.9244 15.7899 15.7899C15.9244 15.6553 16 15.4729 16 15.2826V5.71739C16 5.52713 15.9244 5.34466 15.7899 5.21012C15.6553 5.07558 15.4729 5 15.2826 5H5.47826C5.35142 5 5.22977 5.05039 5.14008 5.14008C5.05039 5.22977 5 5.35142 5 5.47826V15.2826C5 15.4729 5.07558 15.6553 5.21012 15.7899C5.34466 15.9244 5.52713 16 5.71739 16Z"
            fill="#8997DC"/>
      <path d="M2 14V3C2 2.44772 2.44772 2 3 2H14" stroke="#8997DC" strokeLinecap="round"/>
    </svg>
  )
}

export const getBotIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99902 3.99805V0.998047H5.99902"
        stroke="#36FF6C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.499 3.99805H4.49902C3.6706 3.99805 2.99902 4.66962 2.99902 5.49805V11.498C2.99902 12.3265 3.6706 12.998 4.49902 12.998H13.499C14.3275 12.998 14.999 12.3265 14.999 11.498V5.49805C14.999 4.66962 14.3275 3.99805 13.499 3.99805Z"
        stroke="#36FF6C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.49902 8.49805H2.99902M14.999 8.49805H16.499M11.249 7.74805V9.24805M6.74902 7.74805V9.24805"
        stroke="#36FF6C"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  )
}

export const getDeleteIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L15.9992 16" stroke="#9C9FA4" strokeWidth="3" strokeLinecap="round"/>
      <path d="M16 2L2.00081 16" stroke="#9C9FA4" strokeWidth="3" strokeLinecap="round"/>
    </svg>
  )
}

export const getPauseIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3V13" stroke="#8997DC" strokeWidth="4.49999" strokeLinecap="round"/>
      <path d="M12 3V13" stroke="#8997DC" strokeWidth="4.49999" strokeLinecap="round"/>
    </svg>
  )
}