import React, { FC, useEffect, useId, useState } from 'react';
import classNames from 'classnames';
import { Col, Collapse, ProgressBar, Row, Stack } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';
import { toast } from "react-toastify";

import { milisecondsToDate, showProgress } from '../../../utils/helpers';
import { MarketsTabsType } from '../../../types/enums';
import { MarketsTableInput } from '../MarketsTableInput/MarketsTableInput';
import { MarketsTableSecondSubRow } from "../MarketsTableSubRow/MarketsTableSecondSubRow";
import { ModalProfitLoss } from "../../Modals/ModalProfitLoss/ModalProfitLoss";
import { getTrashIcon } from "../../../services/icons";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";


interface Props {
  data: any,
  counterEarning: boolean,
  tabType: MarketsTabsType,
  averageQuantity: number,
  profitValue: number,
  profitPercent: any,
  marketPrice: number,
  show: boolean,
  value: string
  groupData?: any,
}

export const MarketsTableRowSecondPosition: FC<Props> = ({
                                                           data,
                                                           counterEarning,
                                                           tabType,
                                                           averageQuantity,
                                                           profitValue,
                                                           profitPercent,
                                                           marketPrice,
                                                           show,
                                                           value,
                                                           groupData
                                                         }) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [type, setType] = useState<string>();
  const tP = tabType === 'sell' ? bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - data.symbol.counterStepSize * 3, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + data.symbol.counterStepSize * 3, data.counterRound))
  const sL = tabType === 'sell' ? bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + data.symbol.counterStepSize * 3, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - data.symbol.counterStepSize * 3, data.counterRound))

  const collapseId = useId();

  const [stopLoss, setStopLoss] = useState<string>(data?.secondStopLossPlacedPrice || "");
  const [takeProfit, setTakeProfit] = useState<string>(data?.secondTakeProfitPlacedPrice || "");

  const handleSendData = ({newValue, focused, type}: {
    newValue: string | number,
    focused: boolean,
    type?: string
  }) => {
    setIsFocused(focused);
    setType(type);
  };

  const dateString = milisecondsToDate(data.firstFilledTime);
  const dataBuyCreationPrice = bigDecimal.stripTrailingZero(bigDecimal.round(data.firstFilledPrice, data.counterRound));

  const currentSymbol = counterEarning ? data.symbol.counterCurrency : data.symbol.baseCurrency;
  const currentSymbolValue = data.symbol.counterCurrency;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;
  const dataFilledPrice = data.firstFilledPrice;

  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function handleKeyDownSL(event: any) {
    if (event.keyCode === 13 && event.target.value) {
      const newStopLoss = event.target.value;
      const id = toast.loading("Please wait...", {closeButton: true});

      const sendValue = {
        market: "binance",
        symbolName: data.symbol.name,
        blockId: data.blockId,
        stopLossPrice: Number(newStopLoss),
      };

      const method = stopLoss ? 'PUT' : 'POST';

      fetch('/api/markets/spot/third', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendValue),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log('Response Body:', responseData);

          if (responseData.errorMessage === null) {

            const updateOrderData = {
              baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              baseRound: 0,
              blockId: responseData.orderBlocks[0].blockId,
              counterEarning: responseData.orderBlocks[0].counterEarning,
              counterRound: responseData.orderBlocks[0].symbol.counterRound,
              firstFilledCounterQuantity: 0,
              firstFilledPrice: responseData.orderBlocks[0].firstFilledPrice,
              firstFilledQuantity: responseData.orderBlocks[0].firstFilledQuantity,
              firstFilledTime: responseData.orderBlocks[0].firstFilledTime,
              firstFilledUnrealizedQuantity: responseData.orderBlocks[0].firstFilledUnrealizedQuantity,
              firstOrderId: responseData.orderBlocks[0].firstOrderId,
              firstPlacedPrice: responseData.orderBlocks[0].firstPlacedPrice,
              firstPlacedQuantity: responseData.orderBlocks[0].firstPlacedQuantity,
              firstPlacedTime: responseData.orderBlocks[0].firstPlacedTime,
              open: responseData.orderBlocks[0].open,
              secondFirstStopLossFilledCounterQuantity: 0,
              secondFirstStopLossPlacedCounterQuantity: 0,
              secondFirstStopLossPlacedQuantity: responseData.orderBlocks[0].secondFirstStopLossPlacedQuantity,
              secondFirstTakeProfitFilledCounterQuantity: 0,
              secondFirstTakeProfitPlacedCounterQuantity: 0,
              secondFirstTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondFirstTakeProfitPlacedQuantity,
              secondStopLossFilledCounterQuantity: 0,
              secondStopLossFilledPrice: 0,
              secondStopLossFilledQuantity: 0,
              secondStopLossFilledTime: 0,
              secondStopLossOrderId: 0,
              secondStopLossPlacedCounterQuantity: 0,
              secondStopLossPlacedPrice: responseData.orderBlocks[0].secondStopLossPlacedPrice ? responseData.orderBlocks[0].secondStopLossPlacedPrice : 0,
              secondStopLossPlacedQuantity: responseData.orderBlocks[0].secondStopLossPlacedQuantity ? responseData.orderBlocks[0].secondStopLossPlacedQuantity : 0,
              secondStopLossPlacedTime: responseData.orderBlocks[0].secondStopLossPlacedTime ? responseData.orderBlocks[0].secondStopLossPlacedTime : 0,
              secondTakeProfitFilledCounterQuantity: 0,
              secondTakeProfitFilledPrice: 0,
              secondTakeProfitFilledQuantity: 0,
              secondTakeProfitFilledTime: 0,
              secondTakeProfitOrderId: 0,
              secondTakeProfitPlacedCounterQuantity: 0,
              secondTakeProfitPlacedPrice: responseData.orderBlocks[0].secondTakeProfitPlacedPrice ? responseData.orderBlocks[0].secondTakeProfitPlacedPrice : 0,
              secondTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondTakeProfitPlacedQuantity ? responseData.orderBlocks[0].secondTakeProfitPlacedQuantity : 0,
              secondTakeProfitPlacedTime: responseData.orderBlocks[0].secondTakeProfitPlacedTime ? responseData.orderBlocks[0].secondTakeProfitPlacedTime : 0,
              section: responseData.orderBlocks[0].section,
              userName: responseData.orderBlocks[0].userName,
              symbol: {
                name: responseData.orderBlocks[0].symbol.name,
                market: responseData.orderBlocks[0].symbol.market,
                baseStepSize: responseData.orderBlocks[0].symbol.baseStepSize,
                counterStepSize: responseData.orderBlocks[0].symbol.counterStepSize,
                minCounterQuantity: responseData.orderBlocks[0].symbol.minCounterQuantity,
                baseCurrency: responseData.orderBlocks[0].symbol.baseCurrency,
                counterCurrency: responseData.orderBlocks[0].symbol.counterCurrency,
                baseRound: responseData.orderBlocks[0].symbol.baseRound,
                counterRound: responseData.orderBlocks[0].symbol.counterRound,
                baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              }
            };

            const {addFilledOrder} = useNewOrderStore.getState();
            addFilledOrder(responseData.orderBlocks[0].blockId, updateOrderData);

            setStopLoss(responseData.stopLossPrice || newStopLoss);
            toast.update(id, {
              render: 'Stop loss successfully placed',
              type: 'success',
              autoClose: 5000,
              isLoading: false
            });
          } else {
            toast.update(id, {
              render: responseData.errorMessage,
              type: 'warning',
              autoClose: 5000,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          toast.update(id, {
            render: error.message,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
          console.error('Network error:', error)
        });
    }
  }

  function handleKeyDownTP(event: any) {
    if (event.keyCode === 13 && event.target.value) {
      const id = toast.loading("Please wait...", {closeButton: true});
      const newTakeProfit = event.target.value;

      const sendValue = {
        market: "binance",
        symbolName: data.symbol.name,
        blockId: data.blockId,
        takeProfitPrice: Number(newTakeProfit),
      };

      const method = takeProfit ? 'PUT' : 'POST';

      fetch('/api/markets/spot/third', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendValue),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log('Response Body:', responseData);

          if (responseData.errorMessage === null) {

            const updateOrderData = {
              baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              baseRound: 0,
              blockId: responseData.orderBlocks[0].blockId,
              counterEarning: responseData.orderBlocks[0].counterEarning,
              counterRound: responseData.orderBlocks[0].symbol.counterRound,
              firstFilledCounterQuantity: 0,
              firstFilledPrice: responseData.orderBlocks[0].firstFilledPrice,
              firstFilledQuantity: responseData.orderBlocks[0].firstFilledQuantity,
              firstFilledTime: responseData.orderBlocks[0].firstFilledTime,
              firstFilledUnrealizedQuantity: responseData.orderBlocks[0].firstFilledUnrealizedQuantity,
              firstOrderId: responseData.orderBlocks[0].firstOrderId,
              firstPlacedPrice: responseData.orderBlocks[0].firstPlacedPrice,
              firstPlacedQuantity: responseData.orderBlocks[0].firstPlacedQuantity,
              firstPlacedTime: responseData.orderBlocks[0].firstPlacedTime,
              open: responseData.orderBlocks[0].open,
              secondFirstStopLossFilledCounterQuantity: 0,
              secondFirstStopLossPlacedCounterQuantity: 0,
              secondFirstStopLossPlacedQuantity: responseData.orderBlocks[0].secondFirstStopLossPlacedQuantity,
              secondFirstTakeProfitFilledCounterQuantity: 0,
              secondFirstTakeProfitPlacedCounterQuantity: 0,
              secondFirstTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondFirstTakeProfitPlacedQuantity,
              secondStopLossFilledCounterQuantity: 0,
              secondStopLossFilledPrice: 0,
              secondStopLossFilledQuantity: 0,
              secondStopLossFilledTime: 0,
              secondStopLossOrderId: 0,
              secondStopLossPlacedCounterQuantity: 0,
              secondStopLossPlacedPrice: responseData.orderBlocks[0].secondStopLossPlacedPrice ? responseData.orderBlocks[0].secondStopLossPlacedPrice : 0,
              secondStopLossPlacedQuantity: responseData.orderBlocks[0].secondStopLossPlacedQuantity ? responseData.orderBlocks[0].secondStopLossPlacedQuantity : 0,
              secondStopLossPlacedTime: responseData.orderBlocks[0].secondStopLossPlacedTime ? responseData.orderBlocks[0].secondStopLossPlacedTime : 0,
              secondTakeProfitFilledCounterQuantity: 0,
              secondTakeProfitFilledPrice: 0,
              secondTakeProfitFilledQuantity: 0,
              secondTakeProfitFilledTime: 0,
              secondTakeProfitOrderId: 0,
              secondTakeProfitPlacedCounterQuantity: 0,
              secondTakeProfitPlacedPrice: responseData.orderBlocks[0].secondTakeProfitPlacedPrice,
              secondTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondTakeProfitPlacedQuantity,
              secondTakeProfitPlacedTime: responseData.orderBlocks[0].secondTakeProfitPlacedTime,
              section: responseData.orderBlocks[0].section,
              userName: responseData.orderBlocks[0].userName,
              symbol: {
                name: responseData.orderBlocks[0].symbol.name,
                market: responseData.orderBlocks[0].symbol.market,
                baseStepSize: responseData.orderBlocks[0].symbol.baseStepSize,
                counterStepSize: responseData.orderBlocks[0].symbol.counterStepSize,
                minCounterQuantity: responseData.orderBlocks[0].symbol.minCounterQuantity,
                baseCurrency: responseData.orderBlocks[0].symbol.baseCurrency,
                counterCurrency: responseData.orderBlocks[0].symbol.counterCurrency,
                baseRound: responseData.orderBlocks[0].symbol.baseRound,
                counterRound: responseData.orderBlocks[0].symbol.counterRound,
                baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              }
            };

            const {addFilledOrder} = useNewOrderStore.getState();
            addFilledOrder(responseData.orderBlocks[0].blockId, updateOrderData);

            setTakeProfit(responseData.takeProfitPrice || newTakeProfit);

            toast.update(id, {
              render: 'Take profit successfully placed',
              type: 'success',
              autoClose: 5000,
              isLoading: false
            });
          } else {

            toast.update(id, {
              render: responseData.errorMessage,
              type: 'warning',
              autoClose: 5000,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error)
          toast.update(id, {
            render: error.message,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        });
    }
  }

  useEffect(() => {
    setTakeProfit(data?.secondTakeProfitPlacedPrice || "");
  }, [data?.secondTakeProfitPlacedPrice]);

  useEffect(() => {
    setStopLoss(data?.secondStopLossPlacedPrice || "");
  }, [data?.secondStopLossPlacedPrice]);

  return (
    <div className={show ? "" : "d-none"}>
      <Row className='align-items-center justify-content-center fw-bold mt-2'>
        <Col
          onClick={groupData.length > 0 ? () => setOpen(!open) : undefined}
          aria-controls={groupData.length > 0 ? collapseId : ''}
          aria-expanded={groupData.length > 0 ? open : undefined}
          style={groupData.length > 0 ? {cursor: 'pointer'} : {cursor: 'default'}}
          className='ms-md-2 my-3 my-md-0'
          xs={3} md
        >
          <div className={classNames('markets-table__row-main', {open})}>
            <div className={classNames('markets-table__status', {
              'markets-table__status--deep-blue': (tabType === MarketsTabsType.buy || tabType === MarketsTabsType.all) && averageQuantity > 0,
              'markets-table__status--orange': tabType === MarketsTabsType.sell,
              'three': groupData.length > 0,
              'empty': groupData.length === 0,
            })}></div>
          </div>
        </Col>
        <Col md={9} className='mb-3 mb-md-0'>
          <Row className='markets-table__row py-1'>
            <Col xs={4} md className='my-1 my-md-0'>{dateString}</Col>
            <Col xs={4} md className='my-2 my-md-0' style={{color: '#5b6aff'}}>{dataBuyCreationPrice}</Col>
            <Col xs={4} md className='my-1 my-md-0'>{data.symbol.name}</Col>
            <Col xs={4} md className='my-1 my-md-0'>{`${value} ${currentSymbolValue}`}</Col>
            {profitValue > 0
              ? <Col xs={4} md
                     className='text-success my-1 my-md-0'>{`+${profitValue} ${!counterEarning && profitValue > data.baseQuantityStep ? currentSymbol : data.symbol.counterCurrency}`}</Col>
              : <Col xs={4} md
                     className='text-danger my-1 my-md-0'>{`${profitValue} ${!counterEarning ? data.symbol.counterCurrency : currentSymbol}`}</Col>
            }
            {profitPercent > 0
              ? <Col xs={4} md className='text-success my-1 my-md-0'>{`+${Number(profitPercent)}%`}</Col>
              : <Col xs={4} md className='text-danger my-1 my-md-0'>{`${Number(profitPercent)}%`}</Col>
            }
            <Col xs={4} md
                 className={`${tabType === 'buy' ? 'text-success my-1 my-md-0' : 'text-danger my-1 my-md-0'}`}>{capitalize(tabType)}</Col>
            <Col xs={4} md className='my-2 my-md-0'>
              <ProgressBar data-bs-theme='dark'>
                <ProgressBar variant="success" now={averageQuantity} label={showProgress(averageQuantity)} key={1}/>
                <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                             key={2}/>
              </ProgressBar>
            </Col>
            <Col xs={4} md className='my-2 my-md-0'>
              <button type="button" className="" style={{backgroundColor: "transparent"}} disabled>
                {getTrashIcon("#ffffff", "13", "14")}
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Stack direction='horizontal' className='markets-table__inputwrapper position-relative'>
            <MarketsTableInput
              placeHolder={'S.L.'}
              name='sl'
              counterRound={data.counterRound}
              inputValue={stopLoss}
              onKeyDown={handleKeyDownSL}
              sendData={handleSendData}
            />
            <span style={{color: '#7783c0'}}>/</span>
            <MarketsTableInput
              placeHolder={'T.P.'}
              name='tp'
              counterRound={data.counterRound}
              inputValue={takeProfit}
              onKeyDown={handleKeyDownTP}
              sendData={handleSendData}
            />
            <button className='markets-table__button' onClick={handleShowModal}>
              More
            </button>
          </Stack>
          {isFocused && <div
              className="markets-table__error position-absolute">{type === "tp" ? `Min take profit is ${tP}` : `Max stop loss is ${sL}`} </div>}
        </Col>
      </Row>
      {groupData.length > 0 && groupData.map((subRow: any, index: number) => {
        return (
          <Collapse in={open} key={index}>
            <div id={collapseId}>
              <MarketsTableSecondSubRow
                key={index}
                data={subRow}
                tabType={subRow.section.toLowerCase()}
                currentSymbol={currentSymbol}
                counterEarning={counterEarning}
                marketPrice={marketPrice}
              />
            </div>
          </Collapse>
        )
      })}
      {showModal && <ModalProfitLoss
          onClose={() => setShowModal(false)}
          show={showModal}
          type={tabType}
          coinPair={data.symbol.name}
          counterScale={data.counterRound}
          entryPrice={dataFilledPrice}
          symbol={currentSymbol}
          dataFilledPrice={dataFilledPrice}
          filledCounterQuantity={data.firstFilledQuantity}
          counterQuantity={data.firstFilledQuantity * dataFilledPrice}
          currentSymbolValue={currentSymbolValue}
          marketPrice={marketPrice}
          counterRound={data.counterRound}
          counterStep={data.symbol.counterStepSize}
          tp={takeProfit}
          sl={stopLoss}
          id={data.blockId}
      />}
    </div>
  );
};
