import React, { useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";

import '../components/Bot/Bot.scss'
import { Header } from '../components/Header';
import useTitle from '../hooks/useTitle';

const bot_bg = new URL("../assets/images/bot_bg.png", import.meta.url);

export const AlertsPage = () => {
  const [showBotModal, setShowBotModal] = useState(false);
  const [showBotTemplateModal, setShowBotTemplateModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleOpenBotModal = (type: string) => {
    setModalType(type);
    setShowBotModal(true);
  };

  const handleCloseBotModal = () => {
    setShowBotModal(false);
    setModalType('');
  };

  const handleOpenBotTemplateModal = (type: string) => {
    setModalType(type);
    setShowBotTemplateModal(true);
  };

  const handleCloseBotTemplateModal = () => {
    setShowBotTemplateModal(false);
    setModalType('');
  };

  useTitle('CopyMaster – your assistant in crypto-trading');


  // const scrollToTop = () => {
  //   window.scrollTo({top: 0, behavior: 'smooth'});
  // };
  //
  // useEffect(() => {
  //   scrollToTop();
  // }, []);

  return (
    <>
      <Header/>

      <main className='position-relative bot bot-page'
            style={{maxWidth: '80rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <section className='mt-5'>
          <Stack direction='horizontal' className='d-flex justify-content-center w-100'>
            <Row className='mt-5'>
              <Col className='mt-3'>
                <h1 className='roboto-bold'>Active bots</h1>
              </Col>
            </Row>
          </Stack>
        </section>

      </main>
    </>
  );
};

export default AlertsPage;