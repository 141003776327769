import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row, Stack } from 'react-bootstrap';
import classNames from "classnames";
import useAPIStore from "../../../services/hooks/useFetchedDataStore";
import { ModalManageActiveApiRow } from "../ModalManageApi/ModalManageApiRow/ModalManageActiveApiRow";

interface Props {
  show: boolean,
  onClose: () => void,
  activeApi?: any,
  apiList?: any,
}

export const ModalDeleteActiveApi: FC<Props> = ({activeApi, apiList, show, onClose}: Props) => {
  const [currentAction, setCurrentAction] = useState('Keys');
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const {fetchData} = useAPIStore();

  async function handleDeleteActiveApi() {
    const data = activeApi.copyKeys.length > 0
      ? {
        mainUid: activeApi.mainKey.uid,
        deleteUid: activeApi.mainKey.uid,
        replaceUid: selectedRow,
        market: "BINANCE"
      }
      : {
        mainUid: activeApi.mainKey.uid,
        deleteUid: activeApi.mainKey.uid,
        replaceUid: selectedRow,
        market: "BINANCE"
      };

    try {
      const response = await fetch(activeApi.copyKeys.length > 0 ? '/api/key/group/delete' : '/api/key', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });


      if (response.ok) {
        onClose();
        await fetchData();
      } else {
        const errorData = await response.json();

        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  const handleCheckboxChange = (uid: number) => {
    setSelectedRow(uid);
  };

  const hasGroupKeys = apiList && apiList.some((api: any) => api.copyKeys && api.copyKeys.length > 0);
  const hasKeys = apiList && apiList.some((api: any) => !api.copyKeys || api.copyKeys.length === 0);

  useEffect(() => {
    if (!hasGroupKeys && hasKeys) {
      setCurrentAction('Keys');
    } else if (!hasKeys && hasGroupKeys) {
      setCurrentAction('Group keys');
    }
  }, [hasKeys, hasGroupKeys]);

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' size='xl' fullscreen='sm-down' centered
           className='modal--delete modal--active'>
      <Modal.Header closeButton className='justify-content-center'>
        <Modal.Title className='text-center pt-3 pb-1'>Before delete active key {activeApi?.mainKey.name} choose a new
          key</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Container className='px-0'>
          <Row className='modalmanageapi__table-row align-items-center mx-0'
               style={{outline: '1px solid #FF363A', maxHeight: '42px'}}>
            <Col xs={12}>
              <Row>
                <Col xs={2} className='fw-bold'>
                  <div>{activeApi?.mainKey.name}</div>
                </Col>
                <Col xs={4}>{activeApi.copyKeys.length > 0 ? (
                  <>
                    <span style={{color: '#9c9fa4', fontSize: '0.8rem'}}>Keys in:</span> {activeApi.copyKeys.length}
                  </>
                ) : ''}</Col>
                <Col xs={3}>
                  <Stack direction='horizontal' gap={2} className=' align-items-end'>
                    <span style={{color: '#9c9fa4', fontSize: '0.8rem'}}>Market:</span> Binance
                  </Stack>
                </Col>
                <Col xs={3}>
                  <Stack direction='horizontal' gap={2} className='align-items-center flex-wrap'>
                    <span style={{color: '#9c9fa4', fontSize: '0.8rem'}}>Balance:</span>
                    <span className='fw-bold' style={{color: '#36FF6C'}}>{activeApi?.mainKey.usdtBalance} </span>
                    USDT
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='my-4'>
            <Col>
              <h4>Choose key or group of keys</h4>
            </Col>
          </Row>
          <Stack direction='vertical' gap={4}>
            <div className='modalmanageapi__switcher'>
              {hasGroupKeys && (
                <p
                  className={classNames('markets__switcher px-5 py-2', {active: currentAction === 'Group keys'}, {
                    'w-50': hasKeys,
                    'w-100': !hasKeys
                  })}
                  onClick={() => setCurrentAction('Group keys')}
                >
                  Group keys
                </p>
              )}
              {hasKeys && (
                <p
                  className={classNames('markets__switcher px-5 py-2', {active: currentAction === 'Keys'}, {
                    'w-50': hasGroupKeys,
                    'w-100': !hasGroupKeys
                  })}
                  onClick={() => setCurrentAction('Keys')}
                >
                  Keys
                </p>
              )}
            </div>

            <Container className='px-0'>
              <div className='modalmanageapi__maxrows'>
                {currentAction === 'Keys' ? apiList.filter((api: any) => !api.copyKeys || api.copyKeys.length === 0).map((item: any, index: number) => {
                  return <ModalManageActiveApiRow
                    key={index}
                    item={item}
                    selectedRow={selectedRow}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                }) : apiList.filter((api: any) => api.copyKeys && api.copyKeys.length > 0).map((item: any, index: number) => {
                  return <ModalManageActiveApiRow
                    key={index}
                    item={item}
                    selectedRow={selectedRow}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                })}
              </div>
            </Container>
          </Stack>
        </Container>
      </Modal.Body>
      <Modal.Footer className='justify-content-center gap-2'>
        <Button
          variant='secondary'
          style={{borderRadius: '35px'}}
          className='px-4'
          onClick={onClose}
        >Cancel</Button>
        <Button
          variant='primary'
          style={{borderRadius: '35px'}}
          className='px-4'
          onClick={handleDeleteActiveApi}
        >OK</Button>
      </Modal.Footer>
    </Modal>
  )
}