import React, { FC } from 'react';
import { Col, Row } from "react-bootstrap";


interface Props {
  title: string,
  img?: string,
  description?: string,
  width?: number,
  onOpenModal: (modalType: string) => void
}

export const BotTemplate: FC<Props> = ({title, img, description, width, onOpenModal}) => {

  return (
    <div className='bot-template w-100 overflow-hidden d-flex flex-column justify-content-between'>
      <Row style={{height: '63px'}}>
        <Col xs={8}>
          <h3 className='robot-bold'>{title}</h3>
        </Col>
        <Col xs={4}>
          <div className='d-flex flex-column align-items-end'>
            <img src={img} alt={title} width={width}/>
          </div>
        </Col>
      </Row>
      <Row className='pt-0'>
        <Col xs={8}>
          <div className="bot-template__text roboto-regular">{description}</div>
        </Col>
        <Col xs={4} className='text-end'>
          <div className='d-flex flex-column h-100 justify-content-end align-items-end'>
            <button type='button' className='roboto-bold' onClick={() => onOpenModal(title)}>Create</button>
          </div>
        </Col>
      </Row>
    </div>
  );
};