import React, { FC } from 'react';
import { Col, Row } from "react-bootstrap";

import { getBotIcon, getTrashIcon } from "../../../services/icons";

interface Props {
  type: string,
  balance?: number | string,
  pnl?: number | string,
  percent?: number | string,
  status?: boolean,
  onOpenModal: (modalType: string) => void
}

export const BotCard: FC<Props> = ({type, balance, pnl, percent, status = false, onOpenModal}) => {


  return (
    <div className={`bot-card w-100 overflow-hidden ${status ? 'bot-card--active' : ''}`}>
      <div className="d-flex justify-content-between align-items-center pb-1 bot-card__top position-relative">
        <div className='roboto-regular bot-card__head' style={{zIndex: 1}}>XRP/BTC</div>
        <button type='button' className="bg-transparent" disabled>{getTrashIcon('#545D88', '22', '23')}</button>
      </div>
      <div className="bot-card__body position-relative">
        <Row>
          <Col className='position-relative'>
            <div className='bot-card__title roboto-bold bot-card__head pb-1 w-100'>Bot Name</div>
            <div
              className={`position-absolute roboto-regular ${status ? 'bot-card__active' : 'bot-card__inactive'}`}>{status ? 'Active' : 'Inactive'}</div>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <div className="d-flex align-items-center pt-4 pb-3">
              <div className='roboto-regular bot-card__head'>Bot type</div>
              <div className='roboto-bold bot-card__text pe-2' style={{color: '#377BFF'}}>{type}</div>
              {getBotIcon()}
            </div>
            <div className="d-flex align-items-center pb-3">
              <div className='roboto-regular bot-card__head'>PNL</div>
              <div className='roboto-bold bot-card__text pe-2' style={{color: '#36FF6C'}}>{pnl} USDT</div>
              <div className='roboto-regular bot-card__text' style={{color: '#36FF6C'}}>{percent}%</div>
            </div>
            <div className="d-flex align-items-center">
              <div className='roboto-regular bot-card__head'>Balance</div>
              <div className='roboto-bold bot-card__text pe-2' style={{color: '#36FF6C'}}>{balance}</div>
              <div className='roboto-regular bot-card__text'>USDT</div>
            </div>
            <div className="d-flex align-items-center"></div>
          </Col>
          <Col xs={4}>
            <div className='d-flex align-items-end justify-content-end h-100 w-100 bot-card__btn'>
              <button className="btn btn-primary roboto-bold w-100" onClick={() => onOpenModal(type)}>Detail
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};