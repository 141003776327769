import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Header } from '../components/Header';
import { Markets } from '../components/Markets/Markets';
import useTitle from '../hooks/useTitle';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const MarketsPage = () => {
  useTitle('Markets - CopyMaster');

  const {tradeTypeUrl} = useParams();
  const query = useQuery();

  const currentMarketUrl = query.get('market');
  const currentSymbolUrl = query.get('symbolName');
  const counterEarning = query.get('counterEarning');
  const currentSection = query.get('section');

  return (
    <>
      <Header/>

      <Markets
        typeUrl={tradeTypeUrl}
        marketUrl={currentMarketUrl}
        symbolUrl={currentSymbolUrl}
        counterEarningUrl={counterEarning === null ? null : counterEarning === "true" ? true : false}
        section={currentSection}
      />
    </>
  );
};

export default MarketsPage;