import React, { FC, useEffect, useRef, useState } from 'react';

import { getGmailIcon, getTelegramIcon } from "../../services/icons";
import useUserStore from "../../services/hooks/useUserStore";

interface Props {
  placeholder: string,
  image: boolean,
}

const RequestForm: FC<Props> = ({placeholder, image}) => {
  const {userInfo, fetchUserData} = useUserStore();
  const [email, setEmail] = useState(userInfo?.body.email ? userInfo?.body.email : '');
  const [submitDone, setSubmitDone] = useState(false);
  const [areaContent, setAreaContent] = useState('');
  const [type, setType] = useState('');
  const [fileData, setFileData] = useState();
  const [fileName, setFileName] = useState("");

  const handleFile = (file: any) => {
    setFileName(file.name);
  };

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  }

  const handleClick = (event: any) => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: any) => {
    setFileData(event.target.files[0])
    handleFile(event.target.files[0]);
  };

  const sendToTelegram = async () => {
    const telegramBotToken = '7297735334:AAEMRelJ4z3Y9udKh2gCJdBvonuiNIalh2I';
    const chatId = '@copytrader_bot123';
    const message = `New request from ${userInfo?.body.telegram}: ${areaContent}`;

    const sendMessageUrl = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;
    const sendDocumentUrl = `https://api.telegram.org/bot${telegramBotToken}/sendDocument`;

    // Send the text message to Telegram
    await fetch(sendMessageUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
        name: userInfo?.body.username,
        telegram: userInfo?.body.telegram,
      })
    });

    // Send the document to Telegram if there's any file selected
    if (fileData) {
      const formData = new FormData();
      formData.append('chat_id', chatId);
      formData.append('document', fileData);

      await fetch(sendDocumentUrl, {
        method: 'POST',
        body: formData
      });

    }
  };

  const sendToEmail = async () => {
    const emailParams = {
      message: areaContent,
      name: userInfo?.body.username,
      email: userInfo?.body.email,
      fileName: fileName
    };

    const requestOptions = {
      method: 'POST',
      body: areaContent
    };


    fetch('', requestOptions)
      .then(response => response)
      .then(data => {
      });

    // if (fileData) {
    //   emailParams['file'] = fileData;
    // }
    //
    // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', emailParams, 'YOUR_USER_ID')
    //   .then((response) => {
    //     console.log('Email successfully sent!', response.status, response.text);
    //   }, (error) => {
    //     console.error('Failed to send email.', error);
    //   });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!submitDone && areaContent.length > 5) {
      try {
        if (type === 'telegram') {
          await sendToTelegram();
        } else if (type === 'gmail') {
          await sendToEmail();
        }

        setSubmitDone(true);
        alert('Message and file sent successfully!');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      <form onSubmit={handleSubmit} className='faq-form'>
        <div className='d-flex align-items-center gap-4'>
          <div className='d-flex align-items-center justify-content-center gap-3' style={{
            background: '#1C1E29',
            borderRadius: '20px 0 20px 20px',
            height: '68px',
            maxWidth: '125px',
            width: '8%'
          }}>
            <button type='button' className={type === 'telegram' ? 'type type--selected' : 'type p-0'}
                    onClick={() => setType('telegram')}>{getTelegramIcon()}</button>
            <button type='button' className={type === 'gmail' ? 'type type--selected' : 'type p-0'}
                    onClick={() => setType('gmail')}>{getGmailIcon()}</button>
          </div>
          <input
            type="text"
            className='faq-form__email roboto-regular d-flex align-items-center ps-4'
            placeholder='Choose platform where you wantto communicate with us'
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className='mt-3'>
                <textarea
                  placeholder={placeholder}
                  name="faqQuestion"
                  id="faqQuestion" cols={20} rows={5}
                  onChange={e => setAreaContent(e.target.value)}
                  className='w-100 type-text roboto-regular text-white'
                ></textarea>
        </div>
        <div
          className={`d-flex align-items-center w-100 mt-4 ${image ? "justify-content-between" : "justify-content-center"}`}>
          {image && <div className='upload d-flex align-items-center gap-3'>
              <input
                  type="file"
                  className='d-none'
                  id='fileField'
                  size={5120}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={handleChange}
                  ref={hiddenFileInput}
              />
              <div className='d-flex flex-column'>
                  <button
                      type='button'
                      className='upload__faq'
                      onClick={handleClick}
                  >Upload photo
                  </button>
                {fileName && <span style={{fontSize: '13px'}}>{fileName}</span>}
              </div>
              <label htmlFor="avatar" className='roboto-regular'>Upload any attachments you have (Optional).
                  Supported formats: jpg, jpeg,
                  png. Max file size: 5MB</label>
          </div>}
          <button type='submit' className='faq-form__submit' disabled={areaContent.length <= 5}>Send</button>
        </div>
      </form>
    </>
  );
};

export default RequestForm;