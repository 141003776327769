import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import useAPIStore from "../../../services/hooks/useFetchedDataStore";

interface Props {
  show: boolean,
  onClose: () => void,
  del?: number | undefined,
  mainUid?: number | undefined,
  name?: string | undefined,
}

export const ModalDeleteApi: FC<Props> = ({del, mainUid, name, show, onClose}: Props) => {
  const {fetchData} = useAPIStore();

  async function handleDeleteApi() {
    const data = {
      "mainUid": mainUid ? mainUid : del,
      "deleteUid": del,
      "market": "BINANCE"
    };


    try {
      const response = await fetch(mainUid ? '/api/key/group/delete' : '/api/key', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });


      if (response.ok) {
        onClose();
        await fetchData();
      } else {
        const errorData = await response.json();

        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' fullscreen='sm-down' centered className='modal--delete'>
      <Modal.Header closeButton className='justify-content-center'>
        <Modal.Title>Delete {name} API ?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        You cannot undo this action after clicking OK
      </Modal.Body>
      <Modal.Footer className='justify-content-center gap-2'>
        <Button
          variant='secondary'
          className='px-4'
          onClick={onClose}
          style={{backgroundColor: '#99272C', borderRadius: '40px', minWidth: '120px'}}
        >Undo</Button>
        <Button
          variant='danger'
          className='px-4'
          onClick={handleDeleteApi}
          style={{backgroundColor: '#FF363A', borderRadius: '40px', minWidth: '100px'}}
        >OK</Button>
      </Modal.Footer>
    </Modal>
  )
}