import { useCallback, useRef } from 'react';

const useFocus = <T extends HTMLElement>(): [React.RefObject<T>, () => void] => {
  const ref = useRef<T>(null);

  const setFocus = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return [ref, setFocus];
};

export default useFocus;
