import React, { FC, useState } from 'react';
import { Button, Modal, Stack } from 'react-bootstrap';
import { getBotIcon, getPauseIcon, getPencilIcon, getTrashIcon } from "../../../../services/icons";


interface Props {
  show: boolean,
  botTitle: string,
  onClose: () => void,
  handler: () => void
}


export const ModalBotSellBuyLevels: FC<Props> = ({show, onClose, handler, botTitle}) => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [botName, setBotName] = useState<string>('Bot Name');

  function handleChange(event: any) {
    setBotName(event.target.value)
  }

  function handleKeyDown(event: any) {
    if (event.keyCode === 13) {
      setReadOnly(true)
    }
  }

  function handleBlur() {
    if (setBotName.length > 1) {
      setReadOnly(true)
    }
  }

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' fullscreen='sm-down' centered size='xl'
           className="modal--bot">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className='px-5 py-3'>
        <Stack direction='horizontal' gap={3} className='bot__header justify-content-between w-100'>
          <div className="d-fllex flex-col align-items-center justify-content-center">
            <div className="bot__pairTitle roboto-regular">XRP/BTC</div>
            <div className='bot__name'>
              {readOnly ?
                <div className="d-flex gap-1">
                  <div className="roboto-bold">{botName}</div>
                  <span className=""
                        onClick={(event) => setReadOnly(false)}>{getPencilIcon('#BEBFC3', '30', '30')}</span>
                </div> :
                <div>
                  <input
                    type="text"
                    name='botName'
                    className='p-0'
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    value={botName}
                    readOnly={readOnly}
                  />
                </div>
              }
            </div>
          </div>
          <div className='d-flex justify-content-between gap-3'>
            <div className='bot__active roboto-regular'>Active</div>
            <div className='bot__controls d-flex align-items-center justify-content-between gap-4'>
              <button type='button' className='bg-transparent'>{getPauseIcon()}</button>
              <button type='button'
                      className='bg-transparent'>{getTrashIcon('#8997DC', '15', '16')}</button>
            </div>
          </div>
        </Stack>
        <Stack direction='vertical' gap={2} className='w-100'>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-5'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>Bot type</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#377BFF'}}>{botTitle}</div>
                {getBotIcon()}
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Market price</div>
                <div className='d-flex pe-4'>
                  <div className='roboto-bold bot__text pe-2' style={{color: '#377BFF'}}>0.5085475</div>
                  <div className='roboto-regular bot__text'>USDT</div>
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-4'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>PNL</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#36FF6C'}}>+0.67 USDT</div>
                <div className='roboto-regular bot__text pe-2' style={{color: '#36FF6C'}}>+0.01%</div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Buy</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.55 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-1'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>Balance</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#36FF6C'}}>0.00004568</div>
                <div className='roboto-regular bot__text pe-2'>USDT</div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Sell</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.55 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-1'>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                <div className='roboto-regular bot__title'>Run time</div>
                <div className='roboto-bold bot__text pe-2' style={{color: '#377BFF'}}>17d 23h 53m</div>
                <div className='roboto-regular bot__text pe-2'>USDT</div>
              </div>
            </div>
            <div className="bot__column d-flex align-items-center">
              <div className="d-flex align-items-center gap-1 justify-content-between w-100">
                <div className='roboto-regular bot__title'>Invested</div>
                <div className='roboto-bold bot__text'>
                  <input
                    type="text"
                    name='buyPrice'
                    className='p-0 text-end px-3'
                    placeholder=""
                    onChange={handleChange}
                    value='0.00004568 USDT'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bot__row d-flex justify-content-between w-100 gap-3 mt-3'>
            <div className='bot__copied w-100 d-flex align-items-center justify-content-between gap-5'>
              <div className='roboto-regular w-100 px-4 py-3 gap-3 d-flex'>People copied <span
                style={{color: '#377BFF'}}>1738</span>
              </div>
              <button type='button' className='bot__copy roboto-bold'>Copy</button>
            </div>
          </div>
        </Stack>
        <Stack direction='horizontal' gap={3} className='w-100 justify-content-evenly mt-5'>
          <Button variant='secondary' className='px-5 py-2 roboto-bold' style={{borderRadius: '35px'}}
                  onClick={() => onClose()}>Reset changes</Button>
          <Button variant='primary' className='px-5 py-2 roboto-bold' style={{borderRadius: '35px'}}
          > Save</Button>
        </Stack>
      </Modal.Body>
      <Modal.Footer className='py-3'>
      </Modal.Footer>
    </Modal>
  )
}