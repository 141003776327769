import React, { useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";

import '../components/Bot/Bot.scss'
import { Header } from '../components/Header';
import useTitle from '../hooks/useTitle';
import { BotCard } from "../components/Bot/BotCard/BotCard";
import { getDeleteIcon } from "../services/icons";
import { botTemplates } from "../assets/data/datalist";
import { BotTemplate } from "../components/Bot/BotTemplate/BotTemplate";
import { ModalBotSellBuyLevels } from "../components/Modals/ModalBot/ModalBotSellBuyLevels/ModalBotSellBuyLevels";
import {
  ModalBotTemplateSellBuyLevels
} from "../components/Modals/ModalBotTemplate/ModalBotTemplateSellBuyLevels/ModalBotTemplateSellBuyLevels";

const bot_bg = new URL("../assets/images/bot_bg.png", import.meta.url);

export const BotPage = () => {
  const [showBotModal, setShowBotModal] = useState(false);
  const [showBotTemplateModal, setShowBotTemplateModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleOpenBotModal = (type: string) => {
    setModalType(type);
    setShowBotModal(true);
  };

  const handleCloseBotModal = () => {
    setShowBotModal(false);
    setModalType('');
  };

  const handleOpenBotTemplateModal = (type: string) => {
    setModalType(type);
    setShowBotTemplateModal(true);
  };

  const handleCloseBotTemplateModal = () => {
    setShowBotTemplateModal(false);
    setModalType('');
  };

  useTitle('CopyMaster – your assistant in crypto-trading');


  // const scrollToTop = () => {
  //   window.scrollTo({top: 0, behavior: 'smooth'});
  // };
  //
  // useEffect(() => {
  //   scrollToTop();
  // }, []);

  return (
    <>
      <Header/>

      <main className='position-relative bot bot-page'
            style={{maxWidth: '80rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <section className='mt-5'>
          <Stack direction='horizontal' className='d-flex justify-content-center w-100'>
            <Row className='mt-5'>
              <Col className='mt-3'>
                <h1 className='roboto-bold'>Active bots</h1>
              </Col>
            </Row>
          </Stack>
          <Stack direction='horizontal' gap={4}
                 className='flex-wrap flex-lg-nowrap w-100 justify-content-between align-items-end mt-4'>
            <div className="p-0 w-100" style={{maxWidth: '31rem'}}>
              <Stack
                direction='vertical'
                gap={3}
                className='flex-column align-items-center'
              >
                <BotCard
                  type='Future grid bot'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  status={true}
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
            <div className="p-0 w-100">
              <Stack direction='vertical' gap={3} className='pt-0 align-items-end'>
                <div className="bot-card bot-card--main w-100 overflow-hidden h-100 position-relative pt-5">
                  <img src={bot_bg.href} alt="bot bg" className='position-absolute' width='137'/>
                  <div className="bot-card__body position-relative">
                    <button
                      type='button'
                      className='position-absolute bot-card__delete bg-transparent'>{getDeleteIcon()}</button>
                    <Row>
                      <Col xs={8}>
                        <div className="d-flex align-items-center pt-5 pb-3 w-100">
                          <div className='roboto-bold bot-card__head w-100'>You can order your idea<br></br> of the
                            bot from us
                          </div>
                        </div>
                        <div className="d-flex align-items-center pt-2 pb-0 w-100">
                          <div className='roboto-regular bot-card__text w-100'>Leave your contacts and we will
                            be<br></br> in touch with you
                          </div>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className='d-flex align-items-end justify-content-end h-100 w-100 bot-card__btn'>
                          <button className="btn btn-light roboto-bold w-100">Order</button>
                        </div>
                      </Col>

                    </Row>
                  </div>
                </div>
              </Stack>
            </div>
          </Stack>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap w-100 justify-content-between mt-4'>
            <div className="p-0 w-50 mt-2">
              <Stack
                direction='vertical'
                gap={3}
                className='flex-column align-items-center pt-0'
              >
                <BotCard
                  type='Sell/Buy from levels'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  status={true}
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
            <div className="p-0 w-50 mt-2">
              <Stack direction='vertical' gap={3} className='flex-column align-items-center pt-0'>
                <BotCard
                  type='Trend grid bot'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  status={true}
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
          </Stack>
        </section>
        <section>
          <Stack direction='horizontal' className='d-flex justify-content-center w-100 mb-3'>
            <Row className='mt-5 mb-4'>
              <Col className='mt-3'>
                <h2 className='roboto-bold'>Bot templates</h2>
              </Col>
            </Row>
          </Stack>
          <Stack
            direction='horizontal'
            className='d-flex justify-content-center w-100 mb-3 flex-wrap align-items-stretch'
            gap={4}
          >
            {botTemplates.map((template: any, index: number) => {
              return <BotTemplate
                key={index}
                title={template.title}
                img={template.logo.href}
                description={template.description}
                width={template.width}
                onOpenModal={handleOpenBotTemplateModal}
              />
            })}
          </Stack>
          <Stack direction='horizontal' className='d-flex justify-content-center w-100 mb-0'>
            <Row className='mt-4 mb-3'>
              <Col className='mt-3'>
                <button className="btn btn-primary roboto-bold w-100 px-5 py-2">All templates</button>
              </Col>
            </Row>
          </Stack>
        </section>
        <section>
          <Stack direction='horizontal' className='d-flex justify-content-center w-100 mb-3'>
            <Row className='mt-5 mb-4'>
              <Col className='mt-3'>
                <h2 className='roboto-bold'>All bots</h2>
              </Col>
            </Row>
          </Stack>
          <Stack direction='horizontal' gap={4} className='flex-wrap w-100 justify-content-between mt-4'>
            <div style={{flexBasis: '49%'}} className="p-0 mt-2">
              <Stack
                direction='vertical'
                gap={3}
                className='flex-column align-items-center pt-0'
              >
                <BotCard
                  type='Sell/Buy from levels'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
            <div style={{flexBasis: '49%'}} className="p-0 mt-2">
              <Stack direction='vertical' gap={3} className='flex-column align-items-center pt-0'>
                <BotCard
                  type='Trend grid bot'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
            <div style={{flexBasis: '49%'}} className="p-0 mt-2">
              <Stack direction='vertical' gap={3} className='flex-column align-items-center pt-0'>
                <BotCard
                  type='Trend grid bot'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
            <div style={{flexBasis: '49%'}} className="p-0 mt-2">
              <Stack direction='vertical' gap={3} className='flex-column align-items-center pt-0'>
                <BotCard
                  type='Trend grid bot'
                  balance='0.00004568'
                  pnl='+0.67'
                  percent='+0.01'
                  onOpenModal={handleOpenBotModal}
                />
              </Stack>
            </div>
          </Stack>
        </section>

        {modalType === 'Sell/Buy from levels' &&
            <ModalBotSellBuyLevels
                show={showBotModal}
                botTitle='Sell/Buy from levels'
                onClose={handleCloseBotModal}
                handler={() => {
                }}
            />
        }
        {modalType === 'Sell/Buy from levels' &&
            <ModalBotTemplateSellBuyLevels
                show={showBotTemplateModal}
                botTitle='Sell/Buy from levels'
                onClose={handleCloseBotTemplateModal}
                handler={() => {
                }}
                activeTab={'Buy/Sell'}
            />
        }
      </main>
    </>
  );
};

export default BotPage;