import React, { useEffect, useRef, useState } from 'react';
import { Col, Dropdown, DropdownButton, Row, Stack } from "react-bootstrap";

import { donates } from "../assets/data/datalist";
import { Header } from '../components/Header';
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import useTitle from '../hooks/useTitle';
import RequestForm from "../components/RequestForm/RequestForm";
import { getCopyIcon } from "../services/icons";

const bgElement104 = new URL("../assets/images/104.png", import.meta.url);
const bgElement105 = new URL("../assets/images/105.png", import.meta.url);
const btc = new URL("../assets/images/coins/bitcoin.png", import.meta.url);
const xrp = new URL("../assets/images/coins/xrp.png", import.meta.url);
const doge = new URL("../assets/images/coins/doge.png", import.meta.url);
const ether = new URL("../assets/images/coins/ether.png", import.meta.url);
const bnb = new URL("../assets/images/coins/bnb.png", import.meta.url);
const usdt = new URL("../assets/images/coins/usdt.png", import.meta.url);

export const DonatePage = () => {
  const [coin, setCoin] = useState('');
  const [network, setNetwork] = useState('');
  const [areaContent, setAreaContent] = useState('');
  const [copyMemo, setCopyMemo] = useState(false)
  const [copyAddress, setCopyAddress] = useState(false)
  const [copiedText, copy] = useCopyToClipboard()
  const coinTitle = coin ? coin : "Choose Coin";
  const networkTitle = network ? network : "Choose Network";
  const refAddress = useRef(null)
  const refMemo = useRef(null)

  const handleClickOutside = () => {
    setCopyMemo(false);
    setCopyAddress(false);
  }

  useOnClickOutside(refAddress, handleClickOutside)
  useOnClickOutside(refMemo, handleClickOutside)


  useTitle('CopyMaster – your assistant in crypto-trading');

  const handleCopy = (text: string, id: string) => () => {
    copy(text)
      .then(() => {
        console.log('Copied!', {text})
        if (id === "memo") {
          setCopyMemo(true);
        } else {
          setCopyAddress(true);
        }
      })
      .catch(error => {
        console.error('Failed to copy!', error)
      })
  }

  const handleChangeCoin = (item: string) => {
    setCoin(() => item);
    setNetwork('');
  };

  const handleChangeNetwork = (item: string) => {
    setNetwork(() => item)
  }

  const getNetworksForSelectedCoin = () => {
    const selectedCoin = donates.find(d => d.name === coin);

    return selectedCoin ? selectedCoin.list : [];
  };


  const qrCode = getNetworksForSelectedCoin().filter((item: any) => item.network === network)[0]?.qrCode.replaceAll("^\"|\"$", "")
  const address = getNetworksForSelectedCoin().filter((item: any) => item.network === network)[0]?.address
  const qrCodeMemo = getNetworksForSelectedCoin().filter((item: any) => item.network === network)[0]?.qrCodeMemo.replaceAll("^\"|\"$", "")
  const memoTag = getNetworksForSelectedCoin().filter((item: any) => item.network === network)[0]?.memo

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Header/>

      <main className='position-relative donate donate-page'
            style={{maxWidth: '80rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <img src={bgElement104.href} alt=""
             style={{top: '-25px', width: 'auto', height: 'auto', left: 'auto', right: '-60px', zIndex: '-1'}}
             className='position-absolute'/>
        <img src={bgElement105.href} alt=""
             style={{bottom: '-25px', width: 'auto', height: 'auto', right: 'auto', left: '-60px', zIndex: '-1'}}
             className='position-absolute'/>
        <section>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap mt-5'>
            <Row className="w-100 justify-content-between mt-5">
              <Col xs="5" className="p-0">
                <Stack direction='vertical' gap={3} className='flex-column align-items-center pt-3'
                       style={{maxWidth: '29rem'}}>
                  <h1>Support us</h1>
                  <div className="w-100 donate-form" style={{maxWidth: '507px'}}>
                    <div className="donate-select">
                      <DropdownButton
                        id="coin-select"
                        variant="dark"
                        title={coinTitle}
                        className='w-100 bg-transparent roboto-regular mb-4'
                      >
                        {donates.map((item: any, index) => {
                          return <Dropdown.Item
                            key={index}
                            onClick={() => handleChangeCoin(item.name)}
                            bsPrefix='dropdown-item'
                          >
                            {item.name}
                          </Dropdown.Item>
                        })}
                      </DropdownButton>
                    </div>
                    <div className="donate-select">
                      <DropdownButton
                        id="network-select"
                        variant="dark"
                        title={networkTitle}
                        className='w-100 bg-transparent roboto-regular'
                        disabled={!coin}
                      >
                        {getNetworksForSelectedCoin().map((item: any, index) => {
                          return <Dropdown.Item
                            key={index}
                            onClick={() => handleChangeNetwork(item.network)}
                            bsPrefix='dropdown-item'
                          >
                            {item.network}
                          </Dropdown.Item>
                        })}
                      </DropdownButton>
                    </div>
                    <div
                      className={`donate-info w-100 d-flex ${qrCode && qrCodeMemo ? " justify-content-between " : " justify-content-center"}`}>
                      {qrCode &&
                          <div className="donate-address mt-5">
                              <div
                                  className="qr-code"
                                  style={{
                                    background: '#232533',
                                    opacity: 1,
                                    borderRadius: '20px',
                                    padding: '22px',
                                    textAlign: 'center'
                                  }}
                              >
                                  <div
                                      dangerouslySetInnerHTML={{__html: qrCode}}
                                  />
                              </div>
                              <div className="d-flex flex-column">
                                  <div
                                      className="roboto-regular mt-1"
                                      style={{color: '#8997DC', fontSize: '16px'}}>Transfer address:
                                  </div>
                                  <div
                                      className="roboto-regular mt-3 d-flex position-relative"
                                      style={{fontSize: '16px'}}>
                                      <div style={{
                                        fontSize: '16px',
                                        maxWidth: '200px',
                                        overflow: 'hidden'
                                      }}>{address}</div>
                                      <button
                                          type="button"
                                          className="position-absolute bg-transparent"
                                          style={{width: '18px', height: '18px', right: '-25px'}}
                                          onClick={handleCopy(address, "address")}>{getCopyIcon()}</button>
                                    {copyAddress &&
                                        <span
                                            className='position-absolute'
                                            ref={refAddress}
                                            style={{
                                              top: "-20px",
                                              right: '-45px',
                                              padding: '3px 8px',
                                              fontSize: '12px',
                                              color: '#ffffff',
                                              borderRadius: '20px',
                                              backgroundColor: '#ce0a0a'
                                            }}
                                            onClick={handleClickOutside}
                                        >Copied to clipboard</span>}
                                  </div>
                              </div>
                          </div>}
                      {qrCodeMemo &&
                          <div className="donate-address mt-5">
                              <div
                                  className="qr-code mb-1"
                                  style={{
                                    background: '#232533',
                                    opacity: 1,
                                    borderRadius: '20px',
                                    padding: '22px',
                                    textAlign: 'center'
                                  }}
                              >
                                  <div
                                      dangerouslySetInnerHTML={{__html: qrCodeMemo}}
                                  />
                              </div>
                              <div className="d-flex flex-column align-items-start">
                                  <div className="roboto-regular mt-4 mb-2"></div>
                                  <div
                                      style={{fontSize: '16px'}}
                                      className="roboto-regular d-flex position-relative w-100 d-flex justify-content-between">TAG: {memoTag}
                                      <button
                                          type="button"
                                          className="bg-transparent"
                                          style={{width: '18px', height: '18px'}}
                                          onClick={handleCopy(memoTag, "memo")}>{getCopyIcon()}</button>
                                    {copyMemo &&
                                        <span
                                            className='position-absolute'
                                            ref={refMemo}
                                            style={{
                                              top: "-20px",
                                              right: '-45px',
                                              padding: '3px 8px',
                                              fontSize: '12px',
                                              color: '#ffffff',
                                              borderRadius: '20px',
                                              backgroundColor: '#ce0a0a'
                                            }}
                                            onClick={handleClickOutside}
                                        >Copied to clipboard</span>}
                                  </div>
                              </div>
                          </div>}
                    </div>
                    <div
                      className="w-100 d-flex flex-column justify-content-center align-items-center mt-4 roboto-regular"
                      style={{fontSize: "16px"}}>
                      <p className="mb-3 mt-3"><span style={{color: "#8997DC"}}>Or Binance User ID:</span> 36777362</p>
                      <button
                        type="button"
                        className="btn donate-button roboto-bold d-flex align-items-center justify-content-center mt-4 cursor-pointer">Click
                        after donate
                      </button>
                    </div>
                  </div>
                </Stack>
              </Col>
              <Col xs="7" className="p-0">
                <Stack direction='vertical' gap={3} className='pt-3'>
                  <h2>Popular coins</h2>
                  <Row className="mt-4" gap={4}>
                    <Col xs={4} className="mb-4">
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={btc.href} alt="btc" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">BTC</h3>
                      </div>
                    </Col>
                    <Col xs={4} className="mb-4">
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={xrp.href} alt="xrp" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">XRP</h3>
                      </div>
                    </Col>
                    <Col xs={4} className="mb-4">
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={doge.href} alt="doge" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">Doge</h3>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={ether.href} alt="eth" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">ETH</h3>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={bnb.href} alt="bnb" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">BNB</h3>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="d-flex flex-column justify-content-center align-items-center coin-card">
                        <div
                          className="coin-card d-flex align-items-center justify-content-center coin-card__image mb-2">
                          <img src={usdt.href} alt="usdt" width={167} height={167}/>
                        </div>
                        <h3 className="roboto-bold">USDT</h3>
                      </div>
                    </Col>
                  </Row>
                </Stack>
              </Col>
            </Row>
          </Stack>
        </section>
        <section>
          <Stack direction='horizontal' gap={3} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3} style={{border: "3px solid #2A2E41", borderRadius: '20px'}}>
              <div className="donate-function">
                <div>
                  <h2 className='roboto-bold'>Сhoose what you donated to</h2>
                </div>
                <div className="d-flex gap-4 align-items-center mt-3">
                  <DropdownButton
                    variant="dark"
                    title="Or purpose new function"
                    className='w-100 bg-transparent roboto-regular donate-dropdown'
                  >
                    <Dropdown.Item bsPrefix='dropdown-item'>Function 1</Dropdown.Item>
                    <Dropdown.Item bsPrefix='dropdown-item'>Function 2</Dropdown.Item>
                    <Dropdown.Item bsPrefix='dropdown-item'>Function 3</Dropdown.Item>
                    <Dropdown.Item bsPrefix='dropdown-item'>Function 4</Dropdown.Item>
                  </DropdownButton>
                  <button type='submit' className='faq-form__submit' disabled>Send</button>
                </div>
              </div>
              <div className="donate-comment pt-2 pb-5 pe-4 ps-4">
                <textarea
                  name="donateComment" id="donateComment" cols={20} rows={3}
                  className="w-100"
                  onChange={e => setAreaContent(e.target.value)}></textarea>
              </div>
            </Stack>
          </Stack>
        </section>
        <section>
          <Stack direction='horizontal' gap={4} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3} className='mt-5 pt-3'>
              <h2 style={{fontSize: '32px', fontWeight: 700}} className='roboto-bold'>Choose platform where you want to
                communicate with us</h2>
            </Stack>
          </Stack>
          <Stack direction='horizontal' gap={3} className='flex-wrap flex-lg-nowrap mt-5'>
            <Stack direction='vertical' gap={3}>
              <RequestForm placeholder="You can propose all your ideas here and we will greate it" image={false}/>
            </Stack>
          </Stack>
        </section>
      </main>
    </>
  );
};

export default DonatePage;