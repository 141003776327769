import React, { FC } from 'react';
import { Col, Form, Row, Stack } from 'react-bootstrap';

interface Props {
  user: string,
  balance: number,
  currency?: string,
  market?: string
  uid?: number
  onCheckboxChange: (uid: number, isChecked: boolean) => void
}

export const ModalManageApiRow: FC<Props> = ({
                                               user,
                                               balance,
                                               currency = 'USDT',
                                               market = 'Binance',
                                               uid,
                                               onCheckboxChange
                                             }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(uid!, event.target.checked);
  };

  return (
    <Row className='mt-2'>
      <Col xs={11} className='pe-4'>
        <Row className='modal__row align-items-center'>
          <Col xs={5}>
            <div style={{paddingLeft: '1.5rem'}} className='text-start'>{user}</div>
          </Col>
          <Col xs={3} style={{fontSize: '0.8rem'}}><span
            style={{color: '#9c9fa4', fontSize: '0.65rem'}}>Market:</span> {market}</Col>
          <Col xs={4}>
            <Stack direction='horizontal' gap={1} className='align-items-center' style={{fontSize: '0.8rem'}}>
              <span style={{color: '#9c9fa4', fontSize: '0.65rem'}}>Balance:</span>
              <span className='fw-bold' style={{color: '#36FF6C'}}>{balance}</span>
              {currency}
            </Stack>
          </Col>
        </Row>
      </Col>
      <Col className='modal__row d-flex align-items-center justify-content-center' style={{maxWidth: '62px'}}>
        <Form.Check
          type='checkbox'
          id={uid?.toString()}
          onChange={handleChange}
        />
      </Col>
    </Row>
  )
}