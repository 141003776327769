import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';

import './MarketsTable.scss';
import { MarketsTabsType } from '../../types/enums';
import { client } from '../../api/fetchClient';
import { MarketsTableRows } from "./MarketsTableRows/MarketsTableRows";
import { Pagination } from "../Pagination/Pagination";
import useNewOrderStore from "../../services/hooks/useNewOrderStore";

interface Props {
  tabType: MarketsTabsType,
  counterEarning: boolean,
  tradeType: string,
  currentMarket: string,
  currentSymbol: string,
}

export const MarketsTable: FC<Props> = ({
                                          tabType,
                                          counterEarning,
                                          tradeType,
                                          currentMarket,
                                          currentSymbol,
                                        }) => {
  const [isUpdatingTableData, setIsUpdatingTableData] = useState(false);
  const [data, setData] = useState<any>({});
  const [tableData, setTableData] = useState<any>({});
  const [pageNumber, setPageNumber] = useState(0);
  const {newOrder, filledOrder, removeOrder, clearFilledOrder, setRemoveOrder} = useNewOrderStore();

  const handlePageChange = async (page: number) => {
    try {
      const loadedData = await client.get<any>(`/api/markets/${tradeType}/list?market=${currentMarket}&symbolName=${currentSymbol}&counterEarning=${counterEarning}${(tabType === 'buy' || tabType === 'sell') ? `&section=${tabType}&page=${page}` : ''}`);

      if (loadedData.error === 'undefined') {
        return toast.error(loadedData.error);
      }

      const loadedDataContent = loadedData.content;

      const splittedDataContent = page > 0 ? {...tableData, ...loadedDataContent} : loadedDataContent;
      setTableData(splittedDataContent);

      setPageNumber(page);

    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    const getData = async (url: string) => {
      try {
        const loadedData = await client.get<any>('/api/markets' + url);

        if (loadedData.error !== undefined) {
          return toast.error(loadedData.error);
        }
        setData(loadedData);
        setTableData(loadedData.content);

      } catch (error) {
        toast.error(`${error}`);
      }
    };

    getData(`/${tradeType}/list?market=${currentMarket}&symbolName=${currentSymbol}&counterEarning=${counterEarning}${(tabType === 'buy' || tabType === 'sell') ? `&section=${tabType}&page=0` : ''}`);
  }, [counterEarning, currentMarket, currentSymbol, tabType, tradeType]);


  useEffect(() => {
    setTableData((prevTableData: any) => ({...prevTableData, ...newOrder}));
    console.log("New order placed", newOrder)
  }, [newOrder]);

  useEffect(() => {
    if (Object.keys(filledOrder).length > 0 && !isUpdatingTableData) {
      setIsUpdatingTableData(true);
      setTableData((prevTableData: any) => ({...prevTableData, ...filledOrder}));
      clearFilledOrder();
      setIsUpdatingTableData(false);
    }
  }, [filledOrder, clearFilledOrder]);

  useEffect(() => {
    if (removeOrder) {
      const updatedTableData = {...tableData};
      delete updatedTableData[Number(String(removeOrder))];
      setTableData(updatedTableData);
      setRemoveOrder(null);
    }
  }, [removeOrder, setRemoveOrder]);

  useEffect(() => {
    const updateMarketData = async () => {
      try {
        const loadedData = await client.get<any>(`/api/markets/${tradeType}/list?market=${currentMarket}&symbolName=${currentSymbol}&counterEarning=${counterEarning}${(tabType === 'buy' || tabType === 'sell') ? `&section=${tabType}&page=0` : ''}`);
        if (loadedData.error === 'undefined') {
          return toast.error(loadedData.error);
        }
        setTableData(loadedData.content || {});

        // console.log("loadedData.content", loadedData.content)
        setPageNumber(0);
      } catch (error) {
        toast.error(`${error}`);
      }
    };
    updateMarketData();
  }, [tradeType, currentMarket, currentSymbol, counterEarning, tabType]);

  const sortedKeys = Object.keys(tableData);

  let sortedArrayOfObject = sortedKeys.map((v) => {
    return {key: v, value: tableData[v]}
  })

  if (sortedArrayOfObject.length > 0) {
    // console.log("table Data", tableData)
    //console.log("sorted table Data", sortedArrayOfObject)
    // console.log("filled", filledOrder)
  }


  return (
    <div className='markets-table roboto-bold my-4 w-100'>
      <Row className='text-center align-items-center justify-content-center '>
        <Col xs={3} md className='my-3 my-md-0 text-center'>
          <div className='markets-table__row header text-center'>{sortedArrayOfObject.length}</div>
        </Col>
        <Col md={9} className='mb-3 mb-md-0'>
          <Row className='markets-table__row header'>
            <Col xs={4} md className='my-1 my-md-0'>Date</Col>
            <Col xs={4} md className='my-1 my-md-0'>Price</Col>
            <Col xs={4} md className='my-1 my-md-0'>Coin pair</Col>
            <Col xs={4} md className='my-1 my-md-0'>Value</Col>
            <Col xs={4} md className='my-1 my-md-0'>Profit</Col>
            <Col xs={4} md className='my-1 my-md-0'>%</Col>
            <Col xs={4} md className='my-1 my-md-0'>Position</Col>
            <Col xs={4} md className='my-1 my-md-0'>Done</Col>
            <Col xs={4} md className='my-1 my-md-0'></Col>
          </Row>
        </Col>
        <Col xs={2}>
          <div className='markets-table__row header'>Targets</div>
        </Col>
      </Row>

      {sortedArrayOfObject.length > 0 && sortedArrayOfObject.sort((a, b) => b.value[0].firstPlacedTime - a.value[0].firstPlacedTime).map((item: any, index: any) => {

        return (
          <MarketsTableRows
            key={index}
            data={item.value}
            counterEarning={counterEarning}
            tabType={tabType}
          />
        );
      })}

      <Pagination
        lastPageNumber={data?.lastPageNumber || 0}
        pageNumber={pageNumber}
        onPageChange={handlePageChange}
      />
    </div>
  );
};
