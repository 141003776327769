import React, { FC, useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";

import { getCrossEyeIcon, getEyeIcon, getPencilIcon, getTrashIcon } from "../../../services/icons";
import useFocus from "../../../hooks/useFocus";
import useAPIStore from "../../../services/hooks/useFetchedDataStore";
import { ModalDeleteApi } from "../../Modals/ModalDeleteApi/ModalDeleteApi";


interface Props {
  data: any
  mainApi: number
  groupName: string
}

const defaultLogo = new URL("../../../assets/images/avatars/binance.png", import.meta.url);

export const UserApiSubCard: FC<Props> = ({data, mainApi, groupName}) => {
  const [apiName, setApiName] = useState<string>(data.name);
  const [apiKey, setApiKey] = useState<string>(data.key);
  const [readOnlyName, setReadOnlyName] = useState<boolean>(true);
  const [displayKey, setDisplayKey] = useState<boolean>(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteAPI, setDeleteAPI] = useState({
    apiName: '',
    apiKey: 0,
    mainUid: 0,

  });

  const [nameRef, setNameFocus] = useFocus<HTMLInputElement>();
  const {fetchData} = useAPIStore();

  function handleDeleteApi() {
    setDeleteAPI({
      apiName: data?.name,
      apiKey: data?.uid,
      mainUid: mainApi,
    });
    setShowDeleteModal(true)
  }

  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  async function handleRemoveFromGroup() {

    const removeData = {
      "market": "BINANCE",
      "mainUid": mainApi,
      "groupName": groupName,
      "removeUids": [data?.uid]
    };

    try {
      const response = await fetch('/api/key/group/remove', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removeData)
      });


      if (response.ok) {
        await fetchData();
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  }

  function handleChangeName(event: any) {
    setApiName(event.target.value);
  }

  function handleEditName(event: any) {
    setReadOnlyName(false);
    setNameFocus();
  }

  function handleNameKeyDown(event: any) {

    if (event.keyCode === 13 && apiName) {
      setReadOnlyName(true);

      const dataApi = {
        mainUid: mainApi,
        market: "binance",
        name: apiName,
        updateUid: data.uid,
      };

      fetch('/api/key', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataApi),
      })
        .then((response) => {
          if (response.ok) {
            return fetchData();
          } else {
            return response.json().then((errorData) => {
              console.error('Server error:', errorData);
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  function showKey() {
    setDisplayKey(!displayKey)
  }

  return (
    <>
      <Stack direction="horizontal" gap={4}
             className='justify-content-between mx-3 ms-md-5'>
        <Row className='w-100'>
          <Col xs={2} className='d-flex justify-content-center align-items-center'>

            {defaultLogo.href &&
                <img src={defaultLogo.href} alt="api icon" height={100} width={100}/>}

          </Col>
          <Col xs={9}>
            <Stack direction="vertical" gap={2}>
              <Row>
                <Col xs={2} className='text-secondary'>API name</Col>
                <Col xs={10} className='account__grey'>
                  <input
                    type="text"
                    value={apiName}
                    onChange={handleChangeName}
                    onKeyDown={handleNameKeyDown}
                    style={readOnlyName ? {
                      borderRadius: '30px',
                      textIndent: '15px',
                      padding: '5px 0',
                    } : {borderRadius: '30px', textIndent: '15px', outline: '1px solid grey', padding: '5px 0'}}
                    className={`bg-transparent account__input text-white border-0`}
                    ref={nameRef}
                    readOnly={readOnlyName}
                    name='apiName'
                  />
                  <button
                    type='button'
                    className='bg-transparent'
                    onClick={handleEditName}
                  >{getPencilIcon('0.8rem', '0.8rem')}</button>
                </Col>
              </Row>
              <Row>
                <Col xs={2} className='text-secondary'>API key</Col>
                <Col xs={10} className='account__grey d-flex gap-4' style={{textIndent: '15px'}}>
                  <input
                    type={displayKey ? "text" : "password"}
                    value={apiKey}
                    style={{
                      borderRadius: '30px',
                      textIndent: '15px',
                      padding: '5px 0',
                    }}
                    className={`bg-transparent account__input text-white border-0 w-100`}
                    readOnly
                    name='apiKey'
                  />
                  <button type='button' className='bg-transparent' onClick={showKey}>
                    {displayKey ? getEyeIcon() : getCrossEyeIcon()}</button>
                </Col>
              </Row>
              <Row>
                <Col xs={2} className='text-secondary'>Market</Col>
                <Col xs={10} className='account__grey' style={{textIndent: '15px'}}>{data.market}</Col>
              </Row>
              <Row>
                <Col xs={2} className='text-secondary'>Balance</Col>
                <Col xs={10} className='text-success' style={{textIndent: '15px',}}>{data.usdtBalance} USDT</Col>
              </Row>
            </Stack>
          </Col>
          <Col xs={1}>
            <Stack direction="vertical" gap={2} className='justify-content-around align-items-end'>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                  <button
                    className='alerts-table__icon'
                    style={{marginRight: 0, marginLeft: 'auto'}}
                    onClick={handleDeleteApi}
                  >
                    {getTrashIcon("#8997DC")}
                  </button>
                </Col>
              </Row>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                </Col>
              </Row>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                  <button disabled className='alerts-table__icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12"
                         fill="none">
                      <g clipPath="url(#clip0_3459_2211)">
                        <path
                          d="M9.66928 3.21431H0.888835C0.712024 3.21431 0.542454 3.14658 0.41743 3.02602C0.292406 2.90546 0.222168 2.74195 0.222168 2.57145C0.222168 2.40095 0.292406 2.23744 0.41743 2.11688C0.542454 1.99632 0.712024 1.92859 0.888835 1.92859H9.66928C9.8073 1.55257 10.0628 1.22704 10.4006 0.996806C10.7384 0.76657 11.1418 0.642944 11.5555 0.642944C11.9692 0.642944 12.3726 0.76657 12.7104 0.996806C13.0482 1.22704 13.3037 1.55257 13.4417 1.92859H15.1111C15.2879 1.92859 15.4574 1.99632 15.5825 2.11688C15.7075 2.23744 15.7777 2.40095 15.7777 2.57145C15.7777 2.74195 15.7075 2.90546 15.5825 3.02602C15.4574 3.14658 15.2879 3.21431 15.1111 3.21431H13.4417C13.3037 3.59033 13.0482 3.91586 12.7104 4.14609C12.3726 4.37633 11.9692 4.49995 11.5555 4.49995C11.1418 4.49995 10.7384 4.37633 10.4006 4.14609C10.0628 3.91586 9.8073 3.59033 9.66928 3.21431ZM15.1111 10.0714C15.2879 10.0714 15.4574 10.0037 15.5825 9.88316C15.7075 9.7626 15.7777 9.59909 15.7777 9.42859C15.7777 9.2581 15.7075 9.09458 15.5825 8.97402C15.4574 8.85347 15.2879 8.78574 15.1111 8.78574H6.33061C6.19259 8.40971 5.93711 8.08418 5.59932 7.85395C5.26153 7.62371 4.85804 7.50009 4.44439 7.50009C4.03074 7.50009 3.62725 7.62371 3.28946 7.85395C2.95167 8.08418 2.69619 8.40971 2.55817 8.78574H0.888835C0.712024 8.78574 0.542454 8.85347 0.41743 8.97402C0.292406 9.09458 0.222168 9.2581 0.222168 9.42859C0.222168 9.59909 0.292406 9.7626 0.41743 9.88316C0.542454 10.0037 0.712024 10.0714 0.888835 10.0714H2.55817C2.69578 10.4479 2.95111 10.7738 3.28895 11.0044C3.62679 11.235 4.03049 11.3588 4.44439 11.3588C4.85829 11.3588 5.26199 11.235 5.59983 11.0044C5.93767 10.7738 6.193 10.4479 6.33061 10.0714H15.1111Z"
                          fill="#8997DC"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_3459_2211">
                          <rect width="16" height="12" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </Col>
              </Row>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                </Col>
              </Row>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                </Col>
              </Row>
              <Row className='justify-content-around align-items-end'>
                <Col xs={1}>
                  <button
                    className='alerts-table__icon'
                    style={{marginRight: 0, marginLeft: 'auto'}}
                    onClick={handleRemoveFromGroup}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                      <path d="M14.0869 2.08594L1.91401 2.08629" stroke="#8997DC" strokeWidth="2.5"
                            strokeLinecap="round"/>
                    </svg>
                  </button>
                </Col>
              </Row>
            </Stack>
          </Col>
        </Row>
      </Stack>

      {showDeleteModal &&
          <ModalDeleteApi
              del={deleteAPI.apiKey}
              mainUid={deleteAPI.mainUid}
              name={deleteAPI.apiName}
              show={showDeleteModal}
              onClose={handleDeleteClose}
          />
      }
      <hr className='account__hr mb-3'/>
    </>
  );
};