import React, { FC, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { z } from 'zod'
import { toast } from "react-toastify";

import { serverAddress } from "../../../types/enums";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { getCrossEyeIcon, getEyeIcon } from "../../../services/icons";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";

interface Props {
  show: boolean,
  onClose: () => void,
  handler: () => void
}

const defaultLogo = new URL("../../../assets/images/avatars/binance.png", import.meta.url);

export const ModalAddApi: FC<Props> = ({show, onClose, handler}) => {
  const [copiedText, copy] = useCopyToClipboard()
  const [copyAddress, setCopyAddress] = useState(false)
  const [valueApiName, setValueApiName] = useState('');
  const [valueApiKey, setValueApiKey] = useState('');
  const [valueSecretKey, setValueSecretKey] = useState('');
  const [policyChecked, setPolicyChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    apiName: '',
    apiKey: '',
    apiSecretKey: '',
    checkbox: ''
  });
  const [visible, setVisible] = useState<boolean>(false);
  const refAddress = useRef(null)

  const handleClickOutside = () => {
    setCopyAddress(false);
  }

  useOnClickOutside(refAddress, handleClickOutside)

  const addSchema = z.object({
    name: z.string().min(1),
    key: z.string().min(1),
    secret: z.string().min(1)
  })

  const handleCopy = (text: string,) => () => {
    copy(text)
      .then(() => {
        setCopyAddress(true);
      })
      .catch(error => {
        console.error('Failed to copy!', error)
      })
  }

  async function onClickHandle(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    function isJson(str: any) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    }

    const id = toast.loading("Please wait...", {closeButton: true});

    const data = {
      market: 'binance',
      name: valueApiName,
      key: valueApiKey,
      secret: valueSecretKey
    };


    const result = addSchema.safeParse(data);

    if (!result.success || !policyChecked) {
      const fieldErrors = result.error?.formErrors.fieldErrors;
      setErrors({
        apiName: fieldErrors?.name ? 'Field should contain at least 1 character' : '',
        apiKey: fieldErrors?.key ? 'Field should contain at least 1 character' : '',
        apiSecretKey: fieldErrors?.secret ? 'Field should contain at least 1 character' : '',
        checkbox: !policyChecked ? 'You must agree to the usage policy' : ''
      });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });


      if (response.ok) {

        const responseData = await response.json();

        if (responseData.error && responseData.error !== 'undefined') {
          toast.update(id, {
            render: isJson(responseData.error)
              ? JSON.parse(responseData.error).msg
              : responseData.error,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        } else {
          handler();
          onClose();
          toast.update(id, {
            render: 'API successfully added',
            type: 'success',
            autoClose: 5000,
            isLoading: false
          });
        }
      } else {
        const errorData = await response.json();
        // Handle server-side validation errors or other server responses here
        console.error('Server error:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
      setIsLoading(false);
    }
  }

  return (
    <Modal show={show} onHide={onClose} data-bs-theme='dark' fullscreen='sm-down' centered size='xl'
           className="modal--api">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <Stack className="flex-row align-items-center justify-content-center">
          <div className='d-flex flex-row align-items-center justify-content-between gap-4 roboto-bold'
               style={{fontSize: '20px'}}>
            {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={80} width={80}/>}
            Don't have an account on the exchange?
            <a className='btn btn-primary px-3 py-2' style={{borderRadius: '35px'}} target='_blank' rel='noreferrer'
               href='https://www.binance.com/activity/referral-entry/CPA?ref=CPA_00A7CU939I'> to registration</a>
          </div>
        </Stack>
        <Stack direction='horizontal' gap={3}>
          <Stack className="flex-row align-items-center justify-content-center">
            {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={222} width={222}/>}
          </Stack>
          <Stack direction='vertical' className='p-4 text-white' gap={2}>
            <div className='pb-2 h4 modal-title'>Add your kontrol API</div>
            <form>
              <Row className='align-items-center pb-3'>
                <Col className="max-fit">API name</Col>
                <Col className='text-white fw-bold'>
                  <div className='alerts-table__inputwrapper-modal p-0'
                       style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                    <input
                      type='text'
                      name='apiName'
                      value={valueApiName}
                      required
                      size={20}
                      onChange={(event) => setValueApiName(event.target.value)}
                      style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                      className='px-4 w-100 h-100 flex align-items-center input'
                    />
                    {errors.apiName && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiName}</div>}
                  </div>
                </Col>
              </Row>
              <Row className='align-items-center pb-3'>
                <Col className="max-fit">API key</Col>
                <Col className='text-white fw-bold'>
                  <div className='alerts-table__inputwrapper-modal p-0'
                       style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                    <input
                      type='text'
                      name='apiKey'
                      value={valueApiKey}
                      required
                      size={20}
                      style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                      onChange={(event) => setValueApiKey(event.target.value)}
                      className='px-4 w-100 h-100 flex align-items-center input'
                    />
                    {errors.apiKey && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiKey}</div>}
                  </div>
                </Col>
              </Row>
              <Row className='align-items-center pb-3'>
                <Col className="max-fit">Secret key</Col>
                <Col className='text-white fw-bold'>
                  <div className='alerts-table__inputwrapper-modal p-0 position-relative'
                       style={{borderRadius: '30px', height: '40px', maxWidth: '320px', marginLeft: 'auto'}}>
                    <input
                      type={visible ? 'text' : 'password'}
                      name='apiSecretKey'
                      value={valueSecretKey}
                      required
                      size={20}
                      style={{background: 'transparent', outline: 'none', border: '0', borderRadius: '30px'}}
                      onChange={(event) => setValueSecretKey(event.target.value)}
                      className='px-4 w-100 h-100 flex align-items-center input '
                    />
                    {errors.apiSecretKey && <div style={{color: 'red', fontSize: '12px'}}>{errors.apiSecretKey}</div>}
                    <div className="position-absolute" onClick={() => setVisible(!visible)}
                         style={{right: '15px', top: '10px'}}>{visible ? getEyeIcon() : getCrossEyeIcon()}</div>
                  </div>
                </Col>
              </Row>
              <Row className='align-items-center pb-2'>
                <Col className="max-fit">IP address</Col>
                <Col className='text-white fw-bold'>
                  <div className="d-flex align-items-center justify-content-sm-start roboto-regular"
                       style={{
                         borderRadius: '30px',
                         height: '40px',
                         maxWidth: '320px',
                         marginLeft: 'auto',
                         color: '#8997DC',
                       }}><span>{serverAddress.ip}</span>
                    <div className='position-relative'>
                      <button onClick={handleCopy(serverAddress.ip)} type='button' className='ms-2 roboto-bold'
                              style={{
                                backgroundColor: 'transparent',
                                color: '#36FF6C'
                              }}>Copy
                      </button>
                      {copyAddress &&
                          <span
                              ref={refAddress}
                              onClick={handleClickOutside}
                              className='position-absolute'
                              style={{
                                top: "3px",
                                right: '-123px',
                                padding: '3px 8px',
                                fontSize: '12px',
                                color: '#ffffff',
                                borderRadius: '20px',
                                backgroundColor: '#ce0a0a'
                              }}>Copied to clipboard</span>}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='pt-4'>
                <Col>
                  <Stack direction='horizontal' gap={2}>
                    <Form.Check
                      id='testId'
                      name='usagePolicy'
                      required
                      onChange={(event) => setPolicyChecked(event.target.checked)}
                      style={{border: '1px solid #7B7C85', borderRadius: '2px'}}
                      className='api-checkbox d-flex'
                    />
                    I have read the <Link to='#' style={{color: '#3548FE'}}>Usage Policy</Link>
                  </Stack>
                  {errors.checkbox && <div style={{color: 'red', fontSize: '12px'}}>{errors.checkbox}</div>}
                </Col>
              </Row>
              <Row className='justify-content-center pt-3 pb-0'>
                <Col gap={2}>
                  <Stack direction='horizontal' gap={3}>
                    <Button variant='secondary' className='px-5' style={{borderRadius: '35px'}}
                            onClick={() => onClose()}>Decline</Button>
                    <Button variant='primary' className='px-5' style={{borderRadius: '35px'}} disabled={isLoading}
                            onClick={onClickHandle}> {isLoading ? 'Saving...' : 'Next'}</Button>
                  </Stack>
                </Col>
              </Row>
            </form>
          </Stack>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}