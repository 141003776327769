import React, { FC, useEffect, useState } from 'react';
import { Col, Collapse, Row, Stack } from 'react-bootstrap';
import classNames from 'classnames';

import useAPIStore from "../../../services/hooks/useFetchedDataStore";
import useFocus from "../../../hooks/useFocus";
import { ModalDeleteApi } from '../../Modals/ModalDeleteApi/ModalDeleteApi';
import { ModalAddApi } from '../../Modals/ModalAddApi/ModalAddApi';
import { ModalManageApi } from '../../Modals/ModalManageApi/ModalManageApi';
import { ModalDeleteActiveApi } from "../../Modals/ModalDeleteApi/ModalDeleteActiveApi";
import { getActiveIcon, getAddictionIcon, getPencilIcon, getTrashIcon } from "../../../services/icons";
import { ControlApiSubCard } from "../ControlApiSubCard/ControlApiSubCard";


interface Props {
  data: any,
  active: number | undefined,
}

const defaultLogo = new URL("../../../assets/images/avatars/binance.png", import.meta.url);

export const ControlApiCard: FC<Props> = ({data, active}) => {

  const [openRepeatAccounts, setOpenRepeatAccounts] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteActiveModal, setShowDeleteActiveModal] = useState(false);
  const [showAddApiModal, setShowAddApiModal] = useState(false);
  const [showManageApiModal, setManageApiModal] = useState(false);
  const [tabApiName, setTabApiName] = useState('Addiction');
  const [valueGroup, setValueGroup] = useState<string>(data.groupName);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [readOnlyName, setReadOnlyName] = useState<boolean>(true);
  const [apiName, setApiName] = useState<string>(data?.mainKey?.name);

  const [manageApi, setManageApi] = useState({
    apiName: '',
    apiUid: 0,
    balance: 0,
    group: [],
    groupName: ''
  });
  const [deleteAPI, setDeleteAPI] = useState({
    apiName: '',
    apiKey: 0,
    mainUid: 0 || undefined,
  });
  const [deleteActiveApi, setDeleteActiveApi] = useState({
    activeAPI: {},
    apiList: [],
  })
  const [errors, setErrors] = useState({
    groupName: ''
  });

  const [nameRef, setNameFocus] = useFocus<HTMLInputElement>();

  const {fetchedData, fetchData} = useAPIStore();

  useEffect(() => {
    setApiName(data?.mainKey?.name);
  }, [data?.mainKey?.name]);

  function handleDeleteApi() {

    if (data.copyKeys.length > 0) {
      setShowDeleteActiveModal(true)
      setDeleteActiveApi({
        activeAPI: data,
        apiList: data.copyKeys
      });

    } else if (active === data?.mainKey?.uid && data.copyKeys.length === 0 && fetchedData.apiKeyMarketGroups[0].apiKeyGroups.length > 0) {
      setShowDeleteActiveModal(true)
      setDeleteActiveApi({
        activeAPI: data,
        apiList: fetchedData.apiKeyMarketGroups[0].apiKeyGroups
      });
    } else {
      setShowDeleteModal(true)
      setDeleteAPI({
        apiName: data?.mainKey?.name,
        apiKey: data?.mainKey?.uid,
        mainUid: undefined,
      });
    }
  }

  function handleDeleteGroupApi() {

    if (active === data?.mainKey?.uid && fetchedData.apiKeyMarketGroups[0].apiKeyGroups.length > 0) {
      setShowDeleteActiveModal(true)
      setDeleteActiveApi({
        activeAPI: data,
        apiList: fetchedData.apiKeyMarketGroups[0].apiKeyGroups
      });


    } else {
      setShowDeleteModal(true)
      setDeleteAPI({
        apiName: data?.mainKey?.name,
        apiKey: data?.mainKey?.uid,
        mainUid: data?.mainKey?.uid,
      });
    }
  }

  function handleChange(event: any) {
    setValueGroup(event.target.value);
  }

  function handleKeyDown(event: any) {
    if (event.keyCode === 13 && valueGroup) {
      setReadOnly(true);
      event.preventDefault();

      const dataApi = {
        mainUid: data.mainKey.uid,
        groupName: valueGroup,
        market: 'binance',
      };

      fetch('/api/key/group/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataApi),
      })
        .then((response) => {
          if (response.ok) {
            return fetchData();
          } else {
            return response.json().then((errorData) => {
              console.error('Server error:', errorData);
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  function handleBlur() {
    if (valueGroup) {
      setReadOnly(true)
    }
  }

  function handleManageAPI(e: any) {
    setManageApiModal(true)
    setManageApi({
      apiName: data?.mainKey?.name,
      apiUid: data?.mainKey?.uid,
      balance: data?.mainKey?.usdtBalance,
      group: data?.copyKeys,
      groupName: data.groupName
    });
    setTabApiName(e.currentTarget.name)
  }

  const handleDeleteClose = async () => {
    if (showDeleteActiveModal) {
      setShowDeleteActiveModal(false)
    } else {
      setShowDeleteModal(false)
    }
    await fetchData();
  };

  //Edit API name
  function handleChangeName(event: any) {
    setApiName(event.target.value);
  }

  function handleEditName(event: any) {
    setReadOnlyName(false);
    setNameFocus();
  }

  function handleNameKeyDown(event: any) {

    if (event.keyCode === 13 && apiName) {
      console.log(data)
      setReadOnlyName(true);

      const dataApi = {
        mainUid: data.mainKey.uid,
        market: "binance",
        name: apiName,
        updateUid: data.mainKey.uid,
      };

      fetch('/api/key', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataApi),
      })
        .then((response) => {
          if (response.ok) {
            return fetchData();
          } else {
            return response.json().then((errorData) => {
              console.error('Server error:', errorData);
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error);
        });
    }
  }

  return (
    <>
      <Stack direction="vertical" gap={0}
             className={`${data.copyKeys && data.copyKeys.length > 0 ? 'subapi-list' : ''}`}>
        {data.copyKeys && data.copyKeys.length > 0 && data.groupName &&
            <div
                aria-controls={data?.mainKey?.uid}
                aria-expanded={openRepeatAccounts}
                className='group-head text-center p-3 d-flex justify-content-center'
            >{readOnly ? <div className="d-flex gap-1 group-name align-items-center" style={{fontWeight: 700}}>
                <div><span style={{color: '#fff'}}>Group name :</span> {data.groupName}</div>
                <span className="d-flex align-items-center"
                      onClick={(event) => setReadOnly(false)}>{getPencilIcon()}</span></div> :
              <div><label className='d-flex align-items-center gap-2 group-input'> Group name :
                <input
                  type="text"
                  name='groupName'
                  required
                  placeholder=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  value={valueGroup}
                  readOnly={readOnly}
                />
              </label>
                {errors.groupName &&
                    <div style={{color: 'red', fontSize: '12px'}} className="text-start">{errors.groupName}</div>}
              </div>}</div>}
        <Stack direction="horizontal" gap={4}
               className={classNames('controlapi__card justify-content-between p-4 w-100', active === data?.mainKey?.uid ? ' main' : '')}>
          {data
            ? <div className="position-relative" style={{marginLeft: '45px', marginRight: '45px'}}>
              {active === data?.mainKey?.uid && (
                <div className='position-absolute -top-2' style={{top: '-20px', left: '-50px'}}>
                  {getActiveIcon()}
                  {' '}
                  <span style={{color: '#36FF6C', fontSize: '0.8rem'}}>Active</span>
                </div>)}
              <div
                style={{cursor: 'pointer'}}
                onClick={() => setOpenRepeatAccounts(!openRepeatAccounts)}>
                {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={138} width={138}/>}
              </div>
            </div>
            : <div>
              <div></div>
              <div>
                {defaultLogo.href && <img src={defaultLogo.href} alt="api icon" height={138} width={138}/>}
              </div>
            </div>}

          <Stack direction="vertical" gap={2} className='justify-content-center'>
            <Row className='align-items-end'>
              <Col xs={1} className='' style={{padding: '5px 0',}}>API name</Col>
              <Col className='text-white'>
                <input
                  type="text"
                  value={apiName}
                  onChange={handleChangeName}
                  onKeyDown={handleNameKeyDown}
                  style={readOnlyName ? {
                    borderRadius: '30px',
                    textIndent: '15px',
                    padding: '5px 0',
                  } : {borderRadius: '30px', textIndent: '15px', outline: '1px solid grey', padding: '5px 0'}}
                  className={`bg-transparent account__input text-white border-0`}
                  ref={nameRef}
                  readOnly={readOnlyName}
                  name='apiName'
                />
                <button
                  type='button'
                  className='bg-transparent'
                  onClick={handleEditName}
                >{getPencilIcon('1.1rem', '1.1rem')}</button>
              </Col>
              <Col xs={1} className='justify-content-end'>
                <button
                  className='alerts-table__icon'
                  style={{marginRight: 0, marginLeft: 'auto'}}
                  onClick={handleDeleteApi}
                >
                  {getTrashIcon()}
                </button>
              </Col>
            </Row>
            <Row className='align-items-end'>
              <Col xs={1} className='p-0'>API key</Col>
              <Col className='account__grey text-white' style={{textIndent: '15px'}}>
                {data?.mainKey?.key}
              </Col>
              <Col xs={1} className=''>
                {data.copyKeys && data.copyKeys.length > 0 &&
                    <button
                        className='alerts-table__icon'
                        style={{marginRight: 0, marginLeft: 'auto'}}
                        onClick={handleDeleteGroupApi}
                    >
                        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M22.5207 11L22.5004 11.4789L22.4993 11.5071L22.0333 18.0354L22.0333 18.0355C22.0243 18.1616 21.9678 18.2797 21.8753 18.3659C21.7827 18.4521 21.661 18.5 21.5345 18.5H17.465C17.3385 18.5 17.2168 18.4521 17.1242 18.3659C17.0317 18.2797 16.9752 18.1616 16.9662 18.0355L16.9662 18.0354L16.5 11.5041L16.5 11.5038L16.5038 11H22.5207Z"
                                fill="white" stroke="#8997DC"/>
                            <path
                                d="M19.7778 9.5H19.2988L19.2782 9.9785L19.2767 10.0137L18.7071 17.8489L18.7071 17.849C18.6945 18.0229 18.6151 18.1876 18.4822 18.3091C18.349 18.4309 18.172 18.5 17.9866 18.5H13.0128C12.8273 18.5 12.6504 18.4309 12.5172 18.3091C12.3842 18.1876 12.3049 18.0229 12.2923 17.849L12.2923 17.8489L11.7224 10.0107L11.7222 10.0039L11.7261 9.5H11.2222H10.6111C10.5787 9.5 10.5494 9.48728 10.5293 9.46748C10.5094 9.44799 10.5 9.42353 10.5 9.4C10.5 9.37647 10.5094 9.35201 10.5293 9.33252C10.5494 9.31272 10.5787 9.3 10.6111 9.3H20.3889C20.4213 9.3 20.4506 9.31272 20.4707 9.33252C20.4906 9.35201 20.5 9.37647 20.5 9.4C20.5 9.42353 20.4906 9.44799 20.4707 9.46748C20.4506 9.48728 20.4213 9.5 20.3889 9.5H19.7778ZM16.7222 7.5C16.7546 7.5 16.7839 7.51272 16.804 7.53252C16.8239 7.55201 16.8333 7.57647 16.8333 7.6C16.8333 7.62353 16.8239 7.64799 16.804 7.66748C16.7839 7.68728 16.7546 7.7 16.7222 7.7H14.2778C14.2454 7.7 14.2161 7.68728 14.196 7.66748C14.1761 7.64799 14.1667 7.62353 14.1667 7.6C14.1667 7.57647 14.1761 7.55201 14.196 7.53252C14.2161 7.51272 14.2454 7.5 14.2778 7.5H16.7222Z"
                                fill="white" stroke="#8997DC"/>
                            <path
                                d="M3.00728 3.85H15.039L15.001 4.70556L14.9987 4.75774L14.0668 17.16L14.0668 17.1602C14.0509 17.3717 13.9507 17.5811 13.7695 17.7415C13.5868 17.9031 13.3371 18 13.069 18H4.93C4.66195 18 4.41221 17.9031 4.22952 17.7415C4.04827 17.5811 3.94806 17.3717 3.9322 17.1602L3.93219 17.16L3 4.75424L3.00728 3.85Z"
                                fill="white" stroke="#8997DC" strokeWidth="2"/>
                            <text x="7" y="14" className="small"
                                  style={{fontSize: '8px', stroke: 'rgba(53,72,254,0.6)'}}>{data.copyKeys.length}</text>
                        </svg>
                    </button>}
              </Col>
            </Row>
            <Row className='align-items-end'>
              <Col xs={1} className='p-0'>Market</Col>
              <Col className='account__grey text-white' style={{textIndent: '15px'}}>{data?.mainKey?.market}</Col>
              <Col xs={1}>
                <button
                  className='alerts-table__icon'
                  style={{marginRight: 0, marginLeft: 'auto'}}
                  onClick={handleManageAPI}
                  name="Addiction"
                >
                  {getAddictionIcon()}
                </button>
              </Col>
            </Row>
            <Row className='align-items-end'>
              <Col xs={1} className='p-0'>Balance</Col>
              <Col
                style={{
                  fontSize: '0.8rem',
                  fontWeight: '700',
                  color: '#36FF6C',
                  textIndent: '15px'
                }}>{data?.mainKey?.usdtBalance} USDT</Col>
              <Col xs={1}>
                <button
                  className='alerts-table__icon'
                  style={{marginRight: 0, marginLeft: 'auto'}}
                  onClick={handleManageAPI}
                  name="Add Sub-API"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M8.99951 2L8.99911 15.9986" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                    <path d="M15.9995 9L2.00088 9.00041" stroke="white" strokeWidth="2.5" strokeLinecap="round"/>
                  </svg>
                </button>
              </Col>
            </Row>
          </Stack>
        </Stack>
        {data.copyKeys && data.copyKeys.length > 0 &&
            <div
                aria-controls={data?.mainKey?.uid}
                aria-expanded={openRepeatAccounts}
                style={{minHeight: '1.1rem', cursor: 'pointer'}}
            >
                <div className='d-flex justify-content-center align-items-center'
                     style={{minHeight: '1.1rem', cursor: 'pointer'}}></div>

                <Collapse in={openRepeatAccounts}>
                    <Stack direction="vertical" gap={5} id={data?.mainKey?.uid} className='my-5 api-subrows'>
                      {data?.copyKeys.map((item: any, index: number) => {
                        return <ControlApiSubCard data={item} key={index} mainApi={data?.mainKey?.uid}
                                                  groupName={data.groupName}/>
                      })}
                    </Stack>
                </Collapse>
            </div>}
      </Stack>

      {showDeleteModal &&
          <ModalDeleteApi
              del={deleteAPI.apiKey}
              mainUid={deleteAPI.mainUid}
              name={deleteAPI.apiName}
              show={showDeleteModal}
              onClose={handleDeleteClose}
          />
      }

      {showDeleteActiveModal &&
          <ModalDeleteActiveApi
              activeApi={deleteActiveApi.activeAPI}
              apiList={deleteActiveApi.apiList}
              show={showDeleteActiveModal}
              onClose={handleDeleteClose}
          />
      }

      {showAddApiModal &&
          <ModalAddApi
              show={showAddApiModal}
              onClose={() => setShowAddApiModal(false)}
              handler={() => setShowAddApiModal(false)}
          />
      }

      {showManageApiModal &&
          <ModalManageApi
              show={showManageApiModal}
              onClose={() => setManageApiModal(false)}
              currentApi={manageApi}
              activeTab={tabApiName}
          />
      }
    </>
  );
};